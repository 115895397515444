import * as actionTypes from './actionTypes';

const initialState = {
  isFetching: false,
  collection: [],
  // Total
  accountUniqueLoginTotalCount: 0,
  contactUniqueLoginTotalCount: 0,
  viewTimeTotalSum: 0,
  viewTimeTotalPercentage: 0,
  contactUniqueLoginTotalPercentage: 0,
  accountUniqueLoginTotalPercentage: 0,
  sortBy: 'viewTimeSum',
  sortOrder: 'desc',
};

export default function accountActivityByType(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_ACCOUNT_ACTIVITY_BY_TYPE:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.REQUEST_ACCOUNT_ACTIVITY_BY_TYPE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.payload,
      };
    case actionTypes.REQUEST_ACCOUNT_ACTIVITY_BY_TYPE_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.ACCOUNT_ACTIVITY_BY_TYPE_SORT_CHANGE:
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    case actionTypes.RESET_ACCOUNT_ACTIVITY_BY_TYPE:
      return initialState;
    default:
      return state;
  }
}
