import * as actionTypes from './actionTypes';

const initialState = {
  isFetching: true,
  isPublic: false,
  name: '',
  firstLoginAt: '',
  startedAt: '',
  finishedAt: '',
  isAdminAnalyticsOnly: false,
};

export default function metadata(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_DEAL_METADATA:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.REQUEST_DEAL_METADATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isPublic: action.payload.isPublic,
        name: action.payload.name,
        firstLoginAt: action.payload.firstLoginAt,
        startedAt: action.payload.startedAt,
        finishedAt: action.payload.finishedAt,
        isVideoOnly: action.payload.isVideoOnly,
        isAdminAnalyticsOnly: action.payload.isAdminAnalyticsOnly,
      };
    case actionTypes.REQUEST_DEAL_METADATA_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.RESET_DEAL_METADATA:
      return initialState;
    default:
      return state;
  }
}
