import { useReducer } from 'react';
import { useRouter } from 'next/router';

import evercallUrl from '@/evercall/infrastructure/evercallUrl';
import URL from '@/evercall/Router/urlConstants';
import {
  EVENTS_PER_PAGE,
  EVENTS_REDUCER_INITIAL_STATE,
  CHAT_EVENTS_ACTION_TYPES,
} from './constants';

import createAction, { IAction } from '@/Framework/State/createAction';
import createAsyncRequest, {
  IReactReducerDispatch,
  TActionTypes,
} from '@/Framework/State/createAsyncRequest';

import { IEventsReducerState, IFetchEventsPayload, IGetActionsPayload } from './interfaces';
import { IDashboardEvent } from '@/evercall/domain/vo/dashboard/Event';
import Container from '@/Framework/DI/Container';
import DashboardRepository from '@/evercall/infrastructure/repository/DashboardRepository';

export const useDashboardIdFromUrl = (): string => {
  const { query } = useRouter();
  return (query.dashboardId as string[])?.length ? query.dashboardId[0] : '';
};

export const getEvercallDashboardLoginCallbackUrl = (dashboardId: string): string => (
  evercallUrl.getUrl([`${ URL.DASHBOARD }/${ dashboardId }`])
);

interface IProps extends IGetActionsPayload {
  ssid: string | null,
  fetchEventsMethod: (payload: { perPage: number } & IFetchEventsPayload) => (
    Promise<{ data: Array<IDashboardEvent>, totalCount: number }>
  ),
}

export const getCommonChatEventsActions = (
  {
    page,
    bufferDataLength,
    roomId,
    ssid,
    fetchEventsMethod,
  }: IProps,
  dispatch: IReactReducerDispatch,
) => {
  const fetchEvents = (types: TActionTypes, payload: IFetchEventsPayload) => {
    const request = createAsyncRequest<typeof fetchEventsMethod>(
      {
        types,
        dispatch,
        method: fetchEventsMethod,
      },
    );
    // NOTE: the default sorting on backend is DESC by occurredAt
    return request({ perPage: EVENTS_PER_PAGE, ...payload });
  };

  const getDashboardInitialEvents = (payload: IFetchEventsPayload) => fetchEvents(
    [null, CHAT_EVENTS_ACTION_TYPES.SET_INITIAL_DATA],
    payload,
  );

  const getDashboardBufferEvents = (payload: IFetchEventsPayload) => fetchEvents(
    [null, CHAT_EVENTS_ACTION_TYPES.SET_BUFFER_DATA],
    payload,
  );

  const loadMoreEvents = (): void => {
    // if there are buffered (i.e. preloaded) events
    if (bufferDataLength > 0) {
      // merge buffer events array into initial events array
      dispatch(createAction(CHAT_EVENTS_ACTION_TYPES.MERGE_BUFFER_EVENTS));
      // if there is a possibility that initial events to load still exist
      if (bufferDataLength === EVENTS_PER_PAGE) {
        // then fetch another portion of buffer events.
        // we add 2 to 'page' value because it doesn't update immediately in this function after MERGE_BUFFER_EVENTS
        getDashboardBufferEvents({ ssid, roomId, page: page + 2 });
      }
    }
  };

  const pushNewEvent = (payload: IDashboardEvent) => dispatch(createAction(
    CHAT_EVENTS_ACTION_TYPES.PUSH_NEW_EVENT,
    payload,
  ));

  const resetDashboardEvents = () => dispatch(createAction(CHAT_EVENTS_ACTION_TYPES.RESET_EVERCALL_DASHBOARD_EVENTS));

  return {
    getDashboardInitialEvents,
    getDashboardBufferEvents,
    loadMoreEvents,
    pushNewEvent,
    resetDashboardEvents,
  };
};

const chatEventsReducer = (state: IEventsReducerState, action: IAction): IEventsReducerState => {
  switch (action.type) {
    case CHAT_EVENTS_ACTION_TYPES.SET_INITIAL_DATA:
      return {
        ...state,
        initialData: [...action.payload.data].reverse(),
        initialTotalCount: action.payload.totalCount,
      };
    case CHAT_EVENTS_ACTION_TYPES.SET_BUFFER_DATA:
      return {
        ...state,
        bufferData: [...action.payload.data].reverse(),
      };
    case CHAT_EVENTS_ACTION_TYPES.MERGE_BUFFER_EVENTS:
      return {
        ...state,
        page: state.page + 1,
        bufferData: [],
        initialData: [
          ...state.bufferData,
          ...state.initialData,
        ],
      };
    case CHAT_EVENTS_ACTION_TYPES.PUSH_NEW_EVENT:
      return {
        ...state,
        newData: [...state.newData, action.payload],
      };
    case CHAT_EVENTS_ACTION_TYPES.RESET_EVERCALL_DASHBOARD_EVENTS:
      return EVENTS_REDUCER_INITIAL_STATE;
    default:
      return state;
  }
};

export const useChatEvents = <T> (
  roomId: string,
  getActions: (payload: IGetActionsPayload, dispatch: IReactReducerDispatch) => T,
): { state: IEventsReducerState } & T => {
  const [state, dispatch]: [IEventsReducerState, IReactReducerDispatch] = useReducer(
    chatEventsReducer,
    EVENTS_REDUCER_INITIAL_STATE,
  );

  return {
    state,
    ...getActions(
      {
        page: state.page,
        bufferDataLength: state.bufferData.length,
        roomId,
      },
      dispatch,
    ),
  };
};

/** Temporary solution to perform force logout to handle tricky cases: FIN-23438 */
export const forceLogoutFromEvercallDashboard = async (
  container: Container,
  payload: { corporateEmail: string, dashboardId: string },
) => {
  const dashboardRepository = container.get<DashboardRepository>(DashboardRepository);
  await dashboardRepository.forceLogoutFromDashboard(payload);
};
