import { hashString } from '@/Framework/dataHelpers/string/hashString';

/**
 * @typedef {{clearCache: Boolean}} CacheOptions
 */

/**
 * Memoize async function result to promise (helps to resolve duplicate queries)
 * To force request pass { clearCache: true }
 */
export function withCache(_: {}, key: string, descriptor: PropertyDescriptor): PropertyDescriptor {
  if (typeof descriptor?.value !== 'function') {
    return descriptor;
  }

  let originalFn = descriptor.value;

  descriptor.value = function decorate(...args) {
    let memoizedStorageKey = `${ key }.${ hashString(JSON.stringify(args.slice(0, -1))) }`;
    let clearCache = false;
    let cacheError = false;

    this[memoizedStorageKey] = this[memoizedStorageKey] || null;

    if (
        args[args.length - 1] &&
        typeof args[args.length - 1] === 'object'
    ) {
      if ('clearCache' in args[args.length - 1]) {
        clearCache = args[args.length - 1].clearCache;
      }
      if ('cacheError' in args[args.length - 1]) {
        cacheError = args[args.length - 1].cacheError;
      }
    }
    if (clearCache) {
      this[memoizedStorageKey] = null;
    }
    if (this[memoizedStorageKey]) {
      return this[memoizedStorageKey];
    }

    // eslint-disable-next-line no-async-promise-executor
    this[memoizedStorageKey] = new Promise(async (resolve, reject) => {
      try {
        let result = await originalFn.apply(this, args);
        return resolve(result);
      } catch (e) {
        if (!cacheError) {
          this[memoizedStorageKey] = null;
        }
        return reject(e);
      }
    });

    return this[memoizedStorageKey];
  };

  return descriptor;
}
