import { Method } from 'constitute';
import Logger from '@/Framework/browser/log/Logger';
import { Connection } from '@dealroadshow/socket-frontend-sdk';
import { PageRenderingState } from '@/Framework/DI';
import config from '@/Framework/config';

export default new Method((pageRenderingState: PageRenderingState): Connection => {
  const connection = new Connection(config.socket.url, {
    autoConnect: pageRenderingState === PageRenderingState.ClientSideRendering,
  });

  connection.onConnect(() => {
    // @ts-ignore
    if (connection.sockJs.transport === 'jsonp-polling') {
      Logger.info('SockJS transport fell back to jsonp-polling usage. Please, check Sentry jsonp-polling errors.');
    }
  });

  return connection;
}, [PageRenderingState]);
