import config from '@/Framework/config';
import buildUrl from '@/Framework/url/helpers/buildUrl';
import base from '@/Framework/url/baseUrl';
import shouldUseInternalNetwork from '@/Framework/Router/Next/shouldUseInternalNetwork';

export default {
  getUrl(...uris: (string | string[])[]): string {
    return buildUrl(config.protocol, `${ config.dmPortalSubdomain }.${ config.globalDomain }`, ...uris);
  },

  getRpcUrl(): string {
    if (shouldUseInternalNetwork()) {
      return this.getInternalRpcUrl();
    }

    return base.getRpcUrl();
  },

  getInternalRpcUrl(): string {
    return `${ config.tenant.dmPortal.api.internal }/rpc`;
  },
};
