import { combineReducers } from 'redux';
import data from './data/reducer';
import dialIn from './dialIn/reducer';
import analytics from './analytics/reducer';
import auditTrail from './auditTrail/reducer';

export default combineReducers({
  data,
  dialIn,
  analytics,
  auditTrail,
});
