import { SessionService } from '@/users/application/Session/SessionService';

let doNotAddSsidCollection = [
  'session.get_current_session',
  'session.get_current_user',
  'session.invalidate',
  'session.prolong',
];

const addSessionData = (container) => async (request) => {
  const sessionService: SessionService = container.get(SessionService);
  if (!request.params || typeof request.params !== 'object') {
    request.params = {};
  }
  if (!request.params.headers || typeof request.params.headers !== 'object') {
    request.params.headers = {};
  }
  if (!request.params.headers.ssid && !doNotAddSsidCollection.includes(request.method)) {
    try {
      const session = await sessionService.getCurrentSession();
      // @ts-ignore
      request.params.headers.ssid = session.ssid;
    } catch (e) {
      // No ssid
    }
  }
  if (!request.params.headers.viewSsid) {
    request.params.headers.viewSsid = sessionService.getViewSsid();
  }
  if (!request.params.headers.viewerId) {
    request.params.headers.viewerId = sessionService.getViewerId();
  }
  request.params.headers.userAgent = sessionService.getUserAgent();
  request.params.headers.fingerprint = await sessionService.getFingerprint();

  return request;
};

export default addSessionData;
