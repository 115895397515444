import React from 'react';
import HeaderBannerContext from '@/Framework/UI/Organisms/HeaderBanner/HeaderBannerContext';
import CookiesBannerContext from '@/Framework/UI/Molecules/CookiesBanner/CookiesBannerContext';
import { IGlobalProps } from '@/Framework/Router/Next/withGlobalProps';
import FeatureToggleContextProvider from '@/Framework/UI/Organisms/FeatureToggle/Context';

interface IProps extends IGlobalProps {
  children: React.ReactNode,
}

const GlobalProps = ({ children, banner, cookiesBanner, featuresConfig }: IProps) => (
  <HeaderBannerContext value={ banner }>
    <CookiesBannerContext value={ cookiesBanner }>
      <FeatureToggleContextProvider featuresConfig={ featuresConfig }>
        { children }
      </FeatureToggleContextProvider>
    </CookiesBannerContext>
  </HeaderBannerContext>
);

export default GlobalProps;
