import Container from './Container';

export enum PageRenderingState {
  ServerSideDataFetching,
  ServerSideRendering,
  ClientSideRendering,
}

const createContainer = (
  pageRenderingState: PageRenderingState = PageRenderingState.ClientSideRendering,
): Container => {
  const container = new Container();

  container.bindValue(PageRenderingState, pageRenderingState);

  return container;
};

export default createContainer;
