import env from '@/Framework/env/env';
import { IOrderBookConfig } from '@/orderBook/domain/vo/OrderBookConfig';

const config: IOrderBookConfig = {
  name: 'OrderBook',
  code: 'orderBook',
  abbr: 'ORB',
  supportEmail: env('CREDENTIALS__SUPPORT__EMAIL'),
  options: {
    isNewApp: false,
  },
  isConsentForEDeliveryRequired: false,
};

export default config;
