import { Dependencies } from 'constitute';
import { v4 as uuid } from 'uuid';
import SessionStorageRepository from '@/Framework/browser/storage/SessionStorageRepository';

const VIEW_SSID_SESSION_STORAGE_KEY = 'viewSsid';

@Dependencies(SessionStorageRepository)
class ViewSsidService {
  private currentUserId: number;

  private currentEntityId: string;

  constructor(
    protected sessionStorageRepo: SessionStorageRepository,
  ) {}

  bindViewSsid = (entityId: string, userId?: number): string => {
    if (!entityId) {
      // eslint-disable-next-line no-console
      console.error('Cannot bind viewSsid, no entityId provided');
      return;
    }
    this.currentUserId = userId;
    this.currentEntityId = entityId;
    let viewSsid = this.getViewSsid();

    if (viewSsid) {
      // eslint-disable-next-line no-console
      console.warn(`Warning! Cannot bind viewSsid, it's already binded. Current viewSsid: ${ viewSsid }`);
      return;
    }

    if (!viewSsid) {
      viewSsid = uuid();
      this.sessionStorageRepo.setItem(`${ VIEW_SSID_SESSION_STORAGE_KEY }.${ this.currentUserId }.${ this.currentEntityId }`, viewSsid);
    }
  };

  getViewSsid = (): string => {
    if (!this.currentEntityId) {
      return undefined;
    }
    return this.sessionStorageRepo.getItem(`${ VIEW_SSID_SESSION_STORAGE_KEY }.${ this.currentUserId }.${ this.currentEntityId }`);
  };

  clearViewSsid = () => this.sessionStorageRepo.removeItem(`${ VIEW_SSID_SESSION_STORAGE_KEY }.${ this.currentUserId }.${ this.currentEntityId }`);
}

export default ViewSsidService;
