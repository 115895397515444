import { createContext, useContext, useEffect, useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import TenantConfig from '@/Framework/Tenant/TenantConfig';
import BannersRepository from '@/condor/infrastructure/repository/banners/BannersRepository';

export const HeaderBannerContext = createContext(null);

const useHeaderBanner = (serverBanner: { [key: string]: any }) => {
  const { container } = useDIContext();
  const bannerRepository = container.get(BannersRepository);
  const [bannerData, setBannerData] = useState(serverBanner);

  const getBanner = async () => {
    try {
      const response = await bannerRepository.getBannerByTenant({
        filter: {
          tenant: TenantConfig.fromHostname()?.code,
        },
      });
      if (!response) {
        setBannerData({});
        return;
      }
      const { color, hash, content } = response;

      setBannerData({
        hash,
        color,
        content,
      });
    } catch (e) {
      setBannerData({});
    }
  };

  useEffect(() => {
    if (bannerData?.hasServerError) {
      getBanner();
    }
  }, [bannerData]);

  return bannerData || {};
};

export const useHeaderBannerContext = () => {
  const context = useContext(HeaderBannerContext);
  if (!context) {
    throw new Error('useHeaderBannerContext must be used within the HeaderBannerContext');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
  value: any,
}

const HeaderBannerContextProvider = ({ children, value }: IProps) => (
  <HeaderBannerContext.Provider value={ useHeaderBanner(value) }>{ children }</HeaderBannerContext.Provider>
);

export default HeaderBannerContextProvider;
