import { Event } from '@sentry/nextjs';

/**
 * This filter is created to mute gtag errors: window.gtag is not a function.
 * https://sentry.io/organizations/finsight-group-inc/issues/3352195443/?project=5192325&query=is%3Aunresolved+window.gtag+is+not+a+function&referrer=issue-stream&statsPeriod=90d
 *
 * @param {Event} event Sentry.Event
 * @returns {boolean}
 */
function hasGtagError(event: Event): boolean {
  const { type, value } = (event.exception?.values || [{}])[0];
  const typeMatches = type === 'TypeError';
  const valueMatches = value === 'window.gtag is not a function';

  return typeMatches && valueMatches;
}

export default hasGtagError;
