import React, { useEffect } from 'react';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import TwoFactorAuthenticationModal from '@/users/ui/components/TwoFactorAuthentication/TwoFactorAuthenticationModal';
import TwoFactorAuthenticationContext from '@/users/application/TwoFactor/TwoFactorAuthenticationContext';

interface IProps {
  children: React.ReactNode,
}

const TwoFactorAuthentication = ({ children }: IProps) => {
  const {
    isTwoFactorAuthenticationRequired,
    setIsTwoFactorAuthenticationRequired,
    logout,
    currentUser,
    session,
  } = useSessionContext();

  useEffect(() => {
    if (!currentUser && !session) {
      setIsTwoFactorAuthenticationRequired(false);
    }
  }, [currentUser, session]);

  const handleModalClose = () => {
    logout({ autoLogout: true, redirect: true });
  };

  const handleSuccess = () => {
    setIsTwoFactorAuthenticationRequired(false);
  };

  return isTwoFactorAuthenticationRequired() ? (
    <TwoFactorAuthenticationModal
      isVisible
      onClose={ handleModalClose }
      successCallback={ handleSuccess }
      showOverlay={ false }
    />
  ) : children;
};

export default (props) => (
  <TwoFactorAuthenticationContext>
    <TwoFactorAuthentication { ...props } />
  </TwoFactorAuthenticationContext>
);
