import ErrorCodeHelper from '@finsight/error-codes';
import Dispatcher, { RpcError } from '@dealroadshow/json-rpc-dispatcher';
import messageMapping from '@/Framework/Message/Mapper/messageMapping';
import getRenderedMessage from '@/Framework/Message/getMessage';
import Logger from '@/Framework/browser/log/Logger';

/**
 * Get message from json rpc error or message code
 *
 * @param {String||Number} code
 * @param {?Object} data
 *
 * @return {String}
 */
export function getMessage(code, data = {}) {
  return getRenderedMessage(code, data);
}

/**
 * Get error message from json rpc error
 *
 * @param {RpcError} error
 * @param {?Object} data
 *
 * @return {String}
 */
export function getErrorMessage(error, data = {}) {
  if (Dispatcher.isBrowserAbortError(error)) {
    return getRenderedMessage(messageMapping.fallback);
  }
  if (!(error instanceof RpcError)) {
    Logger.error('Error from getErrorMessage, but not RpcError.', { error });
    return getRenderedMessage(messageMapping.fallback);
  }

  let fullData = data || {};
  if (('getData' in error)) {
    fullData = { ...error.getData(), ...data };
  }

  if (!('getCode' in error)) {
    return getRenderedMessage(messageMapping.fallback, fullData);
  }

  if (error.getCode() === ErrorCodeHelper.getCodeByName('WRAPPED_ERROR') && error.getData().code) {
    // TS2769: No overload matches this call.
    // @ts-ignore
    error = new Error({ error: { ...error.getData(), data: { ...error.getData().data, ...data } } });
  }

  return getRenderedMessage(messageMapping[error.getCode()], fullData);
}

/**
 * Get error message by code and data
 *
 * @param {Number|String} code
 * @param {?Object} data
 *
 * @return {String|*}
 */
export function getMessageByErrorCode(code, data = {}) {
  return getRenderedMessage(messageMapping[code], data);
}
