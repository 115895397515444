import { Dependencies } from 'constitute';
import { v4 as uuid } from 'uuid';
import config from '@/Framework/config';
import CookieRepository from '@/Framework/browser/storage/CookieRepository';
import isServer from '@/Framework/Router/Next/isServer';

@Dependencies(CookieRepository)
class ViewerIdService {
  constructor(
    protected cookieRepo: CookieRepository,
  ) {
    // Initialize
    this.getViewerId();
  }

  getViewerId = (): string => {
    if (!isServer()) {
      const viewerIdExpires = new Date(this.cookieRepo.get('viewerIdExpires'));
      const now = new Date();
      const timeDiff = viewerIdExpires.getTime() - now.getTime();

      let viewerId = this.cookieRepo.get('viewerId');
      if (
        !viewerIdExpires || !viewerId
        /*
         * Forces update viewer id if less than 10days of TTl last
         * This is used to protect from viewerId update during view session on page reload.
         * */
        || timeDiff < 10 * 24 * 60 * 60 * 1000
      ) {
        viewerId = uuid();
        if (!isServer()) {
          this.cookieRepo.set('viewerId', viewerId);
          this.cookieRepo.set('viewerIdExpires', (new Date(Date.now() + config.session.viewerIdTtl)).toISOString());
        }
      }

      return viewerId;
    }

    return null;
  };
}

export default ViewerIdService;
