import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import finsightLogo from '@/Framework/assets/logo/finsightLogo.inline.svg';
import { Spinner, Modal } from '@dealroadshow/uikit';
import usersUrl from '@/users/infrastructure/usersUrl';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import { useTwoFactorAuthenticationContext, DeliveryMethodType } from '@/users/application/TwoFactor/TwoFactorAuthenticationContext';
import TwoFactorAuthenticationForm from '../TwoFactorAuthenticationForm';
import styles from './twoFactorAuthenticationModal.scss';
import TenantConfig from '@/Framework/Tenant/TenantConfig';
import { useDIContext } from '@/Framework/DI/DIContext';
import LocalStorageRepository from '@/Framework/browser/storage/LocalStorageRepository';

interface IProps {
  isVisible: boolean,
  onClose?: () => void,
  onReset?: () => void,
  title?: string,
  description?: React.ReactNode,
  successCallback?: () => void,
  addMobileTwoFactorEnabled?: boolean,
  showOverlay?: boolean,
}

const IS_2FA_CODE_SENT = '2faCodeSent';

const TwoFactorAuthenticationModal = ({
  isVisible,
  onClose = noop,
  onReset = noop,
  title = 'Two-Factor Authentication',
  description,
  successCallback = noop,
  addMobileTwoFactorEnabled = false,
  showOverlay = true,
}: IProps) => {
  const { currentUser } = useSessionContext();
  const { container } = useDIContext();
  const localStorage = container.get(LocalStorageRepository);

  const [currentVerificationMethod, setCurrentVerificationMethod] = useState<DeliveryMethodType>(
    currentUser?.mobileTwoFactor ? 'phone' : 'email',
  );

  const {
    isFetching,
    errorMessage,
    checkCode,
    sendCode,
    resetTwoFactorData,
  } = useTwoFactorAuthenticationContext();

  const onChangeMethodClick = () => {
    setCurrentVerificationMethod(currentVerificationMethod === 'email' ? 'phone' : 'email');
    resetTwoFactorData();
    localStorage.removeItem(IS_2FA_CODE_SENT);
  };

  useEffect(() => () => {
    onReset();
    resetTwoFactorData();
    localStorage.removeItem(IS_2FA_CODE_SENT);
  }, []);

  useEffect(() => {
    const is2faCodeSent = localStorage.getItem(IS_2FA_CODE_SENT);

    if (isVisible && currentUser && !is2faCodeSent) {
      sendCode(currentVerificationMethod);
      localStorage.setItem(IS_2FA_CODE_SENT, true);
    }
  }, [currentVerificationMethod, isVisible, currentUser]);

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      localStorage.removeItem(IS_2FA_CODE_SENT);
    });
  }, []);

  if (!currentUser || isEmpty(currentUser)) {
    return null;
  }

  const getModalFooter = () => {
    const footerText = `Switch to receive code by ${ currentVerificationMethod === 'phone' ? 'email' : 'text message' }`;

    if (!addMobileTwoFactorEnabled && !currentUser.mobileTwoFactor) {
      return null;
    }

    if (addMobileTwoFactorEnabled && !currentUser.mobileTwoFactor) {
      return (
        <a
          className={ styles.footer }
          href={ usersUrl.getCheckPasswordUrl(TenantConfig.fromHostname().code) }
        >
          { footerText }
        </a>
      );
    }

    return (
      <div
        className={ styles.footer }
        onClick={ onChangeMethodClick }
      >
        { footerText }
      </div>
    );
  };

  const verificationMethod = currentVerificationMethod === 'phone'
    ? ` by text message to: ${ currentUser.mobilePhone }`
    : ` to your registered email address: ${ currentUser.email }`;

  const verificationDescription = (
    <>
      A verification code was just sent { verificationMethod }.
      Enter the code to continue.
    </>
  );

  return (
    <Modal
      isVisible={ isVisible }
      footer={ getModalFooter() }
      className={ styles.verificationModal }
      afterClose={ onClose }
      dataTest="twoFactorAuthenticationModal"
      showOverlay={ showOverlay }
    >
      <div className={ styles.verificationModalContent }>
        { isFetching && (
          <Spinner
            isVisible
            overlay
          />
        ) }
        <div className={ styles.logo }>
          <i dangerouslySetInnerHTML={ { __html: finsightLogo } } />
        </div>
        <h2 className={ styles.title }>
          { title }
        </h2>
        <div className={ styles.description }>
          { description }
          { ' ' }
          { verificationDescription }
        </div>
        <TwoFactorAuthenticationForm
          isFetching={ isFetching }
          error={ errorMessage }
          checkCode={ checkCode }
          sendCode={ sendCode }
          reset={ resetTwoFactorData }
          deliveryMethod={ currentVerificationMethod }
          successCallback={ successCallback }
        />
      </div>
    </Modal>
  );
};

export default TwoFactorAuthenticationModal;
