// @ts-nocheck
import { useEffect } from 'react';
import TenantConfig from '@/Framework/Tenant/TenantConfig';
import isDefined from '@/Framework/dataHelpers/isDefined';
import Logger from '@/Framework/browser/log/Logger';
import config from '@/Framework/config';
import isServer from '@/Framework/Router/Next/isServer';

const getTrackingIdGoogleAnalytics = () => {
  const tenantConfig = TenantConfig.fromHostname();
  if (!tenantConfig) {
    return undefined;
  }
  if (!tenantConfig.ga) {
    Logger.error('GA part of tenant config not found.', { tenantConfig });
  }
  return tenantConfig.ga.trackingId;
};

const initGTAG = (options) => {
  const s = document.createElement('script');
  s.src = `https://www.googletagmanager.com/gtag/js?id=${ options.trackingId }`;
  s.async = true;

  document.head.insertBefore(s, document.head.firstChild);

  window.dataLayer = window.dataLayer || [];

  function gtag() {
    // eslint-disable-next-line no-undef,prefer-rest-params
    window.dataLayer.push(arguments);
  }

  window.gtag = window.gtag || gtag;
  gtag('js', new Date());
  gtag('config', options.trackingId, {
    send_page_view: false,
    linker: {
      domains: config.ga.linkerDomains,
    },
  });

  return { gtag };
};

const handleLocationChange = (gtag) => {
  gtag('event', 'pageview', {
    page_path: (window.location.pathname + window.location.search),
  });
};

const GoogleAnalytics = () => {
  useEffect(() => {
    const trackingId = getTrackingIdGoogleAnalytics();

    if (!isDefined(trackingId) || isServer()) {
      return () => {};
    }

    /**
     * https://finsight.myjetbrains.com/youtrack/issue/FIN-10869
     * When GA is initialized, the navigator object is encapsulated without inheriting the sendBeacon function,
     * to save it, you need to bind the function.
     */
    navigator.sendBeacon = navigator.sendBeacon.bind(navigator);
    const { gtag } = initGTAG({ trackingId });

    gtag('event', 'pageview', {
      page_path: (window.location.pathname + window.location.search),
    });

    window.addEventListener('locationchange', handleLocationChange.bind(null, gtag));

    return () => {
      window.removeEventListener('locationchange', handleLocationChange.bind(null, gtag));
    };
  }, []);

  return null;
};

export default GoogleAnalytics;
