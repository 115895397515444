import config from '@/Framework/config';
import logoutTargets from './logoutTargets';

/**
 * Returns redirect url for logout purposes
 *
 * @param {?Object} options
 *
 * @return {*}
 */
export default function getLogoutTarget(options = { isAutoLogout: true, callbackUrl: window.location.href }) {
  // @ts-ignore
  let { errorCode, errorData, showToast, callbackUrl, tenant, isAutoLogout } = options;
  let target;

  // eslint-disable-next-line no-restricted-syntax
  for (let logoutTarget of logoutTargets) {
    if (logoutTarget.pattern.test(callbackUrl)) {
      target = isAutoLogout ? logoutTarget.autoLogoutTarget : logoutTarget.target;
      break;
    }
  }

  if (!target) {
    target = `${ config.protocol }//${ config.usersSubdomain }.${ config.globalDomain }/login`;
  }

  const targetParsed = new URL(target);
  const targetGetParams = new URLSearchParams(targetParsed.search);
  const callbackUrlParsed = new URL(callbackUrl);
  const callbackUrlGetParams = new URLSearchParams(callbackUrlParsed.search);

  if (callbackUrl || callbackUrlGetParams.get('callbackUrl')) {
    targetGetParams.set('callbackUrl', callbackUrl || callbackUrlGetParams.get('callbackUrl'));
  }
  if (tenant || callbackUrlGetParams.get('tenant')) {
    targetGetParams.set('tenant', tenant || callbackUrlGetParams.get('tenant'));
  }
  if (errorCode) {
    // eslint-disable-next-line camelcase
    targetGetParams.set('error_code', errorCode);
  }
  if (errorData) {
    // eslint-disable-next-line camelcase
    targetGetParams.set('error_data', encodeURIComponent(JSON.stringify(errorData)));
  }
  if (showToast) {
    // eslint-disable-next-line camelcase
    // @ts-ignore
    targetGetParams.set('show_toast', 1);
  }

  targetParsed.search = targetGetParams.toString();

  return targetParsed.href;
}

global.getLogoutTarget = getLogoutTarget;
