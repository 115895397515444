/**
 * Capitalize a string
 *
 * @param {String} value
 * @return {String}
 */
export default function capitalize(value) {
  if (typeof value !== 'string') {
    return value;
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
}
