import { TUserAccess } from './vo/TUserAccess';

class UserPermission {
  // Settings for investor access: Any user who has valid session (password may be no checked)
  static investorAccess: TUserAccess = {
    authenticated: false,
    authorised: true,
  };

  // Settings for manager access: Any user who has valid session with checked password (or provisioned with SSO)
  static managerAccess: TUserAccess = {
    authenticated: true,
    authorised: true,
  };

  // Settings for public access, no session required
  static publicAccess: TUserAccess = {
    authenticated: false,
    authorised: false,
  };

  /**
   * @param {Boolean} authorised
   * @param {Boolean} authenticated
   * @param {?Object} session
   * @param {?Object} user
   *
   * @return {Boolean}
   */
  static satisfiesAccessPermissions({
    authorised = false,
    authenticated = false,
    session,
    user,
  }) {
    if (authorised && !session) {
      return false;
    }
    if (authenticated && (!session || !user || (!user.twoFactorLoginEnabled && !session.authenticated))) {
      return false;
    }

    return true;
  }

  /**
   * @param {?Object} session
   * @param {?Object} currentUser
   * @return {Boolean}
   */
  static isTwoFactorAuthenticationRequired = (session, currentUser) => (
    !!session &&
    !!currentUser &&
    !session.twoFactor &&
    !session.authenticated &&
    currentUser.twoFactorLoginEnabled
  );
}

export default UserPermission;
