import { useContext } from 'react';
import { ConstitutableContainer } from '@dealroadshow/jsbro/DI';
import { Singleton } from 'constitute';

class Container extends ConstitutableContainer {
  constructor() {
    super();
    Singleton.setCachedInstance(this.constituteContainer, Container, this);
  }

  bindContextValue(key, value) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (useContext(key)) {
      throw new Error(`
      It looks like you are trying to render more than one instance of global context.
      We desire all contexts that are synchronised to DI Container as global. Please make sure that Bootstrap is the
      only place where you render your global contexts.
      `);
    }

    return this.constituteContainer.bindValue(key, value);
  }
}

export default Container;
