import ErrorCodeHelper from '@finsight/error-codes';
import { RpcError } from '@dealroadshow/json-rpc-dispatcher';
import Container from '@/Framework/DI/Container';
import { DataroomTwoFactorAuthenticationContext } from '../../../DataroomTwoFactorAuthenticationContext';

const dataroomTwoFactorAuthentication = (container: Container) => (response) => {
  if (!(response instanceof RpcError)) {
    return response;
  }

  if (response.getCode() === ErrorCodeHelper.getCodeByName('DATAROOM_TWO_FACTOR_VIOLATION')) {
    const { setTwoFactorAccessError, setModalData } = container.get(DataroomTwoFactorAuthenticationContext);

    setTwoFactorAccessError(true);

    setModalData({
      entityName: response.getData()[0].dataroomName,
    });
  }

  return response;
};

export default dataroomTwoFactorAuthentication;
