import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import DmPortalRpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';

@Dependencies(
  DmPortalRpcDispatcher,
)
class UserRepository {
  private dmPortalDispatcher: typeof DmPortalRpcDispatcher;

  constructor(rpc) {
    /**
     * @type {Dispatcher}
     */
    this.dmPortalDispatcher = rpc;
  }

  /**
   * List users through drs proxy
   *
   * @param {Object} data
   * @return {Promise.<*>}
   */
  async roadshowList(data = {}) {
    let request = new Request('user.queries.get_users', data);
    let response = await this.dmPortalDispatcher.call(request);

    return response.getResult().payload;
  }

  /**
   * Resend verification email
   *
   * @param {String} email
   * @param {?String} entryCode
   * @param {?String} roadshowId
   *
   * @returns {Promise<*>}
   */
  async resendVerificationEmail({ email, entryCode, roadshowId }) {
    let request = new Request('user.resend_verification_email', { email, entryCode, roadshowId });

    return this.dmPortalDispatcher.request(request);
  }
}

export default UserRepository;
