import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import UsersRpcDispatcher from '@/users/application/DI/Rpc/HttpDispatcher';
import ExternalDispatcher from '@/Framework/DI/Providers/Rpc/externalApi/HttpDispatcher';

@Dependencies(
  UsersRpcDispatcher,
  ExternalDispatcher,
)
class UserRepository {
  private usersRpc: typeof UsersRpcDispatcher;

  private external: typeof ExternalDispatcher;

  constructor(usersRpc, external) {
    /**
     * @type {Dispatcher}
     */
    this.usersRpc = usersRpc;

    /**
     * @type {Dispatcher}
     */
    this.external = external;
  }

  /**
   * @param {Object} data
   * @return {Promise.<*>}
   */
  list = async (data = {}) => {
    let request = new Request('user.list', data);
    let response = await this.usersRpc.call(request);

    return response.getResult();
  };

  /**
   * Register user
   *
   * @param {Object} data
   * @return {Promise<*>}
   */
  async register(data) {
    let request = new Request('user.register', data);
    let response = await this.usersRpc.call(request);

    return response.getResult();
  }

  /**
   * Complete user profile
   *
   * @param {Object} data
   * @return {Promise<*>}
   */
  async completeProfile(data) {
    let request = new Request('user.complete_profile', data);
    let response = await this.usersRpc.call(request);

    return response.getResult();
  }

  /**
   * @param {Object} userData
   * @param {String} ssid
   *
   * @return {Promise.<*>}
   */
  async completeInvestorProfile(userData, ssid = null) {
    let req = new Request('user.complete_investor_profile', userData, { ...ssid && { ssid } });
    let response = await this.usersRpc.call(req);

    return response.getResult();
  }

  /**
   * Request reset password
   *
   * @param {Object} data
   * @return {Object}
   */
  async sendForgotPasswordEmail(data) {
    let request = new Request('user.send_forgot_password_email', data);
    let response = await this.usersRpc.call(request);

    return response.getResult().payload;
  }

  /**
   * Reset password
   *
   * @param {Object} data
   * @return {Object}
   */
  async resetPassword(data) {
    let request = new Request('user.reset_password', data);
    let response = await this.usersRpc.call(request);

    return response.getResult().payload;
  }

  /**
   * Resend activation email
   *
   * @param {Object} data
   *
   * @returns {Promise<*>}
   */
  async resendActivationEmail(data) {
    let request = new Request('user.resend_activation_email', data);
    let response = await this.usersRpc.call(request);

    return response.getResult();
  }

  /**
   * @param {String} email
   * @param {Boolean} aggregateCompany
   * @return {Promise.<*>}
   */
  async getUserByEmail(email, aggregateCompany = false) {
    let req = new Request('user.get_by_email', { email, aggregateCompany });
    let response = await this.usersRpc.call(req);

    return response.getResult();
  }

  /**
   * @param {String|Number} id
   * @param {Boolean} aggregateCompany
   * @return {Promise.<*>}
   */
  getUserById = async (id, aggregateCompany = false) => {
    let req = new Request('user.get_by_id', { id, aggregateCompany });
    let response = await this.usersRpc.call(req);

    return response.getResult();
  };

  /**
   * @param {String} email
   * @param {String} activationCode
   * @param {Boolean} aggregateCompany
   * @return {Promise.<*>}
   */
  async getUserByActivationCode(email, activationCode, aggregateCompany = false) {
    let req = new Request('user.get_by_activation_code', { email, activationCode, aggregateCompany });
    let response = await this.usersRpc.call(req);

    return response.getResult();
  }

  /**
   * @param {Object} userData
   *
   * @return {Promise.<*>}
   */
  async update(userData) {
    let req = new Request('user.update', userData);
    let response = await this.usersRpc.call(req);

    return response.getResult();
  }

  /**
   * @param {String} oldPassword
   * @param {String} newPassword
   *
   * @return {Promise.<*>}
   */
  async updatePassword(oldPassword, newPassword) {
    let req = new Request('user.update_password', { oldPassword, newPassword });
    let response = await this.usersRpc.call(req);

    return response.getResult();
  }

  /**
   * @param {String} password
   * @return {Promise.<*>}
   */
  async verifyPassword(password) {
    let req = new Request('user.verify_password', { password });
    let response = await this.usersRpc.call(req);

    return response.getResult();
  }

  /**
   * @param {String|Number} id
   * @param {String} tenant
   * @param {String} callbackUrl
   *
   * @return {Promise.<*>}
   */
  async regenerateForgotPasswordUrl(id, tenant, callbackUrl) {
    let req = new Request('user.regenerate_forgot_password_url', { id, tenant, callbackUrl });
    let response = await this.usersRpc.call(req);

    return response.getResult().url;
  }

  /**
   * @param {String|Number} id
   *
   * @return {Promise.<*>}
   */
  async regeneratePassword(id) {
    let req = new Request('user.regenerate_password', { id });
    let response = await this.usersRpc.call(req);

    return response.getResult();
  }

  /**
   * @param {String} email
   * @param {String} tenant
   * @param {String} callbackUrl
   *
   * @return {Promise.<*>}
   */
  async getActivationUrl(email, tenant, callbackUrl) {
    let req = new Request('user.get_activation_url', { email, tenant, callbackUrl });
    let response = await this.usersRpc.call(req);

    return response.getResult().url;
  }

  /**
   * @param {String} email
   *
   * @return {Promise.<*>}
   */
  async regenerateActivationCode(email) {
    let req = new Request('user.regenerate_activation_code', { email });
    let response = await this.usersRpc.call(req);

    return response.getResult();
  }

  /**
   * @param {Object} userData
   *
   * @return {Promise.<*>}
   */
  edit = async (userData) => {
    let req = new Request('user.edit', { ...userData });
    let response = await this.usersRpc.call(req);

    return response.getResult();
  };

  /**
   * @param {Number} userId
   *
   * @return {Promise.<*>}
   */
  getApiAccessKey = async (userId) => {
    let req = new Request('internal.user.get_token', { userId });
    let response = await this.external.call(req);

    return response.getResult().token;
  };

  /**
   * @param {Number} userId
   *
   * @return {Promise.<*>}
   */
  setApiAccessKey = async (userId) => {
    let req = new Request('internal.user.set_token', { userId });
    let response = await this.external.call(req);

    return response.getResult().user.token;
  };

  /**
   * Check email domain validation
   * @param {String} email
   * @returns {Promise.<boolean>}
   */
  checkEmailDomainValidation = async (email) => {
    let req = new Request('account.queries.is_email_domain_valid', { email });

    let response = await this.usersRpc.call(req);
    return Boolean(response.getResult());
  };
}

export default UserRepository;
