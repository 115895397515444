import callbackMapping from '@/Framework/Message/Mapper/callbackMapping';
import isServer from '@/Framework/Router/Next/isServer';
import Container from '@/Framework/DI/Container';

declare global {
  interface Window {
    execCallback: (type: string, params: object) => void,
    gtag: (action: string, eventName: string, params?: object) => void,
  }
}

const registerMessagesExecCallback = (container: Container) => {
  const execCallback = (type, params) => {
    if (!callbackMapping[type]) {
      throw `Callback ${ type } was not found in message callback mapping`;
    }
    callbackMapping[type](container, JSON.parse(decodeURIComponent(params)));
  };

  if (!isServer()) {
    window.execCallback = execCallback;
  }
};

export default registerMessagesExecCallback;
