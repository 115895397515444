import base from './baseUrl';

export default {

  /**
   * Get external api url
   *
   * @return {String}
   */
  getRpcUrl() {
    return base.getApiUrl('external/rpc');
  },
};
