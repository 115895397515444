import { device } from '@/Framework/browser/device';

/**
 * Downloads document from external server
 *
 * @param {string} url
 */
export default function downloadFile(url) {
  if (device().mobile()) {
    window.location = url;
    return;
  }

  let downloadIframe = document.getElementById('downloadFile');
  if (downloadIframe === null) {
    downloadIframe = document.createElement('iframe');
    downloadIframe.id = 'downloadFile';
    downloadIframe.setAttribute('style', 'display: none; visibility: hidden');
    document.body.appendChild(downloadIframe);
  }

  downloadIframe.setAttribute('src', url);
}
