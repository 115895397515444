import { RpcError as ApplicationRpcError, JsonRpcError } from '@dealroadshow/json-rpc-dispatcher';

/**
 * Checks if event is JsonRpcError
 *
 * @param event
 * @param hint
 * @return {boolean}
 */
function hasUnhandledRpcError(event, hint) {
  return hint.originalException instanceof ApplicationRpcError &&
         !(hint.originalException instanceof JsonRpcError);
}

export default hasUnhandledRpcError;
