import { Dependencies } from 'constitute';
import JsonRpcDispatcher, { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import UsersRpcDispatcher from '@/users/application/DI/Rpc/HttpDispatcher';
import { ISsoCertificate } from '@/users/domain/vo/sso/ISsoCertificate';
import { ISsoCertificatePayload } from '@/users/domain/vo/sso/ISsoCertificatePayload';
import { IGetValuesResponse, ISSOPayload } from '@/users/domain/vo/sso/SSOForm';

@Dependencies(UsersRpcDispatcher)
class SSORepository {
  private readonly usersRpc: JsonRpcDispatcher;

  constructor(usersRpc: JsonRpcDispatcher) {
    this.usersRpc = usersRpc;
  }

  getValues = async (data: { accountId: number }):Promise<IGetValuesResponse> => {
    let request = new Request('sso.find_by_account_id', data);
    let response = await this.usersRpc.call<RpcSuccess>(request);

    return response.getResult();
  };

  checkIsSSOEmail = async (data) => {
    let request = new Request('sso.is_sso_login_required', data);
    let response = await this.usersRpc.call<RpcSuccess>(request);

    return response.getResult();
  };

  checkDomains = async (data: { domains: string[] }) => {
    let request = new Request('sso.find_existing_domains', data);
    let response = await this.usersRpc.call<RpcSuccess>(request);

    return response.getResult();
  };

  submitSSOForm = async (data: ISSOPayload) => {
    let request = new Request('sso.upsert_by_account_id', data);
    let response = await this.usersRpc.call<RpcSuccess>(request);

    return response.getResult();
  };

  checkShortname = async (data: { shortname: string }) => {
    let request = new Request('sso.find_by_shortname', data);
    let response = await this.usersRpc.call<RpcSuccess>(request);

    return response.getResult();
  };

  async getListCertificates(accountId: number): Promise<ISsoCertificate[]> {
    let request = new Request('sso.get_certificates_by_account_id', { accountId });
    let response = await this.usersRpc.call<RpcSuccess>(request);

    return response.getResult();
  }

  async addCertificate(payload: ISsoCertificatePayload) {
    let request = new Request('sso.create_certificate', payload);
    let response = await this.usersRpc.call<RpcSuccess>(request);

    return response.getResult();
  }

  async deleteCertificate(id: string) {
    let request = new Request('sso.delete_certificate', { id });
    let response = await this.usersRpc.call<RpcSuccess>(request);

    return response.getResult();
  }
}

export default SSORepository;
