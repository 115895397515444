import { Dependencies } from 'constitute';
import config from '@/Framework/config';
import CookieRepository from '@/Framework/browser/storage/CookieRepository';
import ClientReqData from '@/Framework/Router/Next/headers/ClientReqData';
import formatUuid from '@/Framework/dataHelpers/formatters/formatUuid';
import Fingerprint from '@fingerprintjs/fingerprintjs';
import isServer from '@/Framework/Router/Next/isServer';

@Dependencies(CookieRepository, ClientReqData)
class DeviceInfoService {
  private userAgent: string = undefined;

  constructor(
    protected cookieRepo: CookieRepository,
    protected clientReqData: ClientReqData,
  ) {
    this.userAgent = this.clientReqData.userAgent;
    this.getUserAgent = this.getUserAgent.bind(this);
  }

  init({ fingerprint }) {
    if (fingerprint) {
      this.cookieRepo.set('fingerprint', fingerprint, {
        domain: `.${ config.host }`,
      });
    }
  }

  getUserAgent() {
    return this.userAgent;
  }

  getFingerprint = async (): Promise<string> => {
    let fingerprint = this.cookieRepo.get('fingerprint');

    if (!fingerprint) {
      fingerprint = formatUuid.addDashes(await this.calculateFingerprint());
      this.cookieRepo.set('fingerprint', fingerprint, {
        domain: `.${ config.host }`,
      });
    }

    return fingerprint;
  };

  // eslint-disable-next-line no-async-promise-executor
  private calculateFingerprint = async () => {
    if (isServer()) {
      return 'server-request-fingerprint-needs-fix';
    }
    const fp = await Fingerprint.load({ monitoring: false });
    const result = await fp.get();
    return Fingerprint.hashComponents(result.components);
  };
}

export default DeviceInfoService;
