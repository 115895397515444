import { getCollectionReducer } from '@/Framework/State/Redux/collectionFactory';
import actionTypes from './actionTypes';

const newState = {
  sortBy: 'occuredAt',
  sortOrder: 'desc',
  releaseDate: null,
};

export default getCollectionReducer(actionTypes, newState, {
  [actionTypes.GET_COLLECTION_SUCCESS]: (state, action) => (
    {
      ...state,
      data: action.payload.data,
      totalCount: action.payload.totalCount,
      isFetching: false,
      isError: false,
      releaseDate: null,
    }
  ),
  [actionTypes.GET_COLLECTION_ERROR]: (state, action) => {
    const { code, data } = action.payload.error;
    return {
      ...state,
      isFetching: false,
      isError: true,
      releaseDate: code === 2006 ? data?.releaseDate : null,
    };
  },

});
