import messages from './messages';

/**
 * Insert data into template
 *
 * @param {String} template
 * @param {Object} data
 * @param {?String} keyPrefix
 *
 * @return {*}
 */
function injectData(template, data, keyPrefix = '') {
  Object.keys(data).forEach((key) => {
    let tplKey = keyPrefix ? `${ keyPrefix }.${ key }` : `${ key }`;
    if (typeof data[key] === 'object' && data[key] !== null) {
      injectData(template, data[key], tplKey);
    }

    template = template.replace(`{{${ tplKey }}}`, data[key]);
  });
  template = template.replace('{{callback_params}}', encodeURIComponent(JSON.stringify(data)));

  return template;
}

/**
 * Get message from code with rendered data
 *
 * @param {String} code
 * @param {?Object} data
 *
 * @return {*}
 */
export default function getMessage(code, data = undefined) {
  let messageText = messages[code] || messages.GENERAL_ERROR;

  if (data) {
    messageText = injectData(messageText, data, '');
  }

  return messageText;
}
