import config from '@/Framework/config';
import TenantConfig from '@/Framework/Tenant/TenantConfig';
import { checkSessionWithZeroTolerance } from '@/users/application/Session/interceptors/helpers';
import Container from '@/Framework/DI/Container';
import { FetchInterceptorResponse } from 'fetch-intercept';

const checkSession = (container: Container) => async (
  response: FetchInterceptorResponse,
): Promise<FetchInterceptorResponse> => {
  const code = response.status;

  // This are our finsight projects, we dont want 403 of any 3d party to be treated.
  const securedHostnames = Object.keys(config.tenant).reduce(
    (acc, key) => [...acc, TenantConfig.fromObject(config.tenant[key]).hostname],
    [
      `${ config.socketSubdomain }.${ config.globalDomain }`,
      `${ config.apiSubdomain }.${ config.globalDomain }`,
    ],
  );

  if (
    // This are our finsight projects, we dont want 403 of any 3d party to be treated.
    securedHostnames.some((hostname) => response.url.includes(hostname)) &&
    // And also rest requests with currentUser in signature are excluded
    !response.url.includes('currentUser') &&
    // This is our trigger, that something went wrong
    (code === 401 || code === 403)
  ) {
    await checkSessionWithZeroTolerance(container);
  }

  return response;
};

export { checkSession };
