import { Event } from '@sentry/nextjs';

/**
 * This filter is created to mute illegal invocation in gtag.
 * - https://finsight.myjetbrains.com/youtrack/issue/FIN-14191
 * - https://sentry.io/organizations/finsight-group-inc/issues/3417895217/?project=5192325
 *
 * @param {Event} event Sentry.Event
 * @returns {boolean}
 */
function hasUnhandledIllegalInvocationInGtagError(event: Event): boolean {
  return (
    event.message === 'Illegal invocation' &&
    // @ts-ignore
    event.stacktrace.frames[1].module === 'gtag/js'
  );
}

export default hasUnhandledIllegalInvocationInGtagError;
