import React, { createContext, useContext, useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { getFeatureToggleConfig, getFeatureToggleCalculatedList, IFeatureToggle, IFeatureToggleItem } from '../helpers';
import { useDIContext } from '@/Framework/DI/DIContext';
import CookieRepository from '@/Framework/browser/storage/CookieRepository';

interface IProps {
  children: React.ReactNode,
  featuresConfig: IFeatureToggle,
}
const useFeatureToggle = (serverFeatureToggleConfig = {}) => {
  const [featuresConfig, setFeaturesConfig] = useState<IFeatureToggle>(serverFeatureToggleConfig);
  const { container } = useDIContext();
  useEffect(() => {
    if (isEmpty(featuresConfig)) {
      const cookieRepository = container.get(CookieRepository);
      const featuresList = getFeatureToggleConfig();
      const cookie = cookieRepository.getAll();
      setFeaturesConfig(getFeatureToggleCalculatedList(cookie, featuresList));
    }
  }, []);

  const getFeatureByName = (featureName: string): IFeatureToggleItem | { isEnabled: boolean } => {
    return featuresConfig[featureName] || { isEnabled: false };
  };
  return {
    featuresConfig,
    getFeatureByName,
  };
};
export const FeatureToggleContext = createContext<ReturnType<typeof useFeatureToggle>>(null);

export const useFeatureToggleContext = () => {
  const context = useContext(FeatureToggleContext);
  if (!context) {
    throw new Error('useFeatureToggleContext must be used within the FeatureToggleContext');
  }
  return context;
};

const FeatureToggleContextProvider = ({ children, featuresConfig }: IProps) => {
  return (
    <FeatureToggleContext.Provider value={ useFeatureToggle(featuresConfig) }>
      { children }
    </FeatureToggleContext.Provider>
  );
};

export default FeatureToggleContextProvider;
