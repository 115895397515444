interface ISyntheticEventEmitterParams<T> {
  detail?: T,
  bubbles?: boolean,
  cancelable?: boolean,
}

class SyntheticEventEmitter {
  private static getCustomEventPolyfill = <T>(event: string, params?: ISyntheticEventEmitterParams<T>) => {
    const customEvent = document.createEvent('CustomEvent');

    customEvent.initCustomEvent(
      event,
      params?.bubbles ?? false,
      params?.cancelable ?? true,
      params?.detail ?? undefined,
    );

    return customEvent;
  };

  private static createCustomEvent = <T>(event: string, params?: ISyntheticEventEmitterParams<T>) => (
    typeof CustomEvent === 'function'
      ? new CustomEvent<T>(event, params)
      : SyntheticEventEmitter.getCustomEventPolyfill<T>(event, params)
  );

  static emit = <T>(event: string, params?: ISyntheticEventEmitterParams<T>) => {
    window.dispatchEvent(SyntheticEventEmitter.createCustomEvent<T>(event, params));
  };
}

export default SyntheticEventEmitter;
