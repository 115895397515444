import urlJoin from './helpers/urlJoin';
import config from '@/Framework/config';
import shouldUseProxy from '@/Framework/url/helpers/shouldUseProxy';

export default {
  getApiUrl(...uris: (string | string[])[]): string {
    const url = shouldUseProxy()
      ? `${ config.protocol }//${ window.location.host }${ config.api.proxy.rest.url }`
      : config.api.rest.url;

    return urlJoin(url, ...uris.flat());
  },

  getRestUrl(...uris: (string | string[])[]): string {
    return urlJoin(config.api.rest.url, ...uris);
  },

  getRpcUrl({ disableProxy = false }: { disableProxy?: boolean } = {}): string {
    return shouldUseProxy() && !disableProxy
      ? `${ config.protocol }//${ window.location.host }${ config.api.proxy.rpc.url }`
      : config.api.rpc.url;
  },

  getAssetsLocation(...uris: (string | string[])[]): string {
    return urlJoin(config.assets.location, ...uris);
  },
};
