import * as actionTypes from './actionTypes';

const initialState = {
  isFetching: true,
  collection: [],
  contactTotalCount: 0,
  page: 1,
  perPage: 25,
  sortBy: 'contactTitle',
  sortOrder: 'asc',
};

export default function contactsActivity(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_CONTACTS_ACTIVITY:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.REQUEST_CONTACTS_ACTIVITY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.payload,
      };
    case actionTypes.REQUEST_CONTACTS_ACTIVITY_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.CONTACTS_ACTIVITY_SORT_CHANGE:
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    case actionTypes.CONTACTS_ACTIVITY_PAGE_CHANGE:
      return {
        ...state,
        page: action.payload.page,
      };
    case actionTypes.CONTACTS_ACTIVITY_ITEMS_PER_PAGE_CHANGE:
      return {
        ...state,
        perPage: action.payload.perPage,
        page: 1,
      };
    case actionTypes.CONTACTS_ACTIVITY_RESET:
      return initialState;
    default:
      return state;
  }
}
