import * as actionTypes from './actionTypes';

const initialState = {
  isFetching: false,
  collection: [],
  viewTimeTotalSum: 0,
};

export default function totalViewTimeBySlide(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_TOTAL_VIEW_TIME_BY_SLIDE:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.REQUEST_TOTAL_VIEW_TIME_BY_SLIDE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.payload,
      };
    case actionTypes.REQUEST_TOTAL_VIEW_TIME_BY_SLIDE_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.TOTAL_VIEW_TIME_BY_SLIDE_RESET:
      return initialState;
    default:
      return state;
  }
}
