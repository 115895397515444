import { useRef, useCallback, useEffect } from 'react';
import trackIdle from '@/Framework/browser/timers/trackIdle';
import config from '@/Framework/config';
import TenantConfig from '@/Framework/Tenant/TenantConfig';
import { SessionService } from '@/users/application/Session/SessionService';
import { useDIContext } from '@/Framework/DI/DIContext';

export default function useProlong() {
  const { container } = useDIContext();
  const sessionService: SessionService = container.get(SessionService);

  let { current: prolongTimer } = useRef(null);
  let { current: lastProlongTime } = useRef(null);

  /**
   * Will prolong session or reset its state if ssid is not valid
   */
  const prolongSession = useCallback(async () => {
    const session = await sessionService.getCurrentSession();
    clearTimeout(prolongTimer);
    lastProlongTime = new Date();
    let tenant: string;
    if (session) {
      try {
        tenant = TenantConfig.fromHostname().code;
      } catch (e) { /* */ }

      await sessionService.prolongSession({ tenant });
    }
    prolongTimer = setTimeout(prolongSession, config.session.prolongInterval);
  }, [lastProlongTime, prolongTimer]);

  /**
   * Init prolong logic and timers
   */
  const initProlong = useCallback(() => {
    prolongSession();
    trackIdle(
      config.session.idleTimeout,
      () => clearTimeout(prolongTimer),
      () => {
        // @ts-ignore
        const timeSinceLastProlong = (new Date()) - lastProlongTime;
        timeSinceLastProlong >= config.session.prolongInterval
          ? prolongSession()
          : prolongTimer = setTimeout(prolongSession, config.session.prolongInterval - timeSinceLastProlong);
      },
    );
  }, [prolongSession]);

  useEffect(() => {
    initProlong();
  }, []);

  return {
    initProlong,
    prolongSession,
  };
}
