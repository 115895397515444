import urlJoin from './urlJoin';

/**
 * @param protocol
 * @param host
 * @param {Array||String} uris
 * @return {String}
 */
export const buildUrl = (protocol, host, ...uris) => urlJoin(`${ protocol }//${ host }`, ...uris);

export default buildUrl;
