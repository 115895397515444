import React, { useEffect } from 'react';
import capitalize from '@/Framework/dataHelpers/string/capitalize';
import styles from './scrollBodyBackground.scss';

interface IProps {
  tenant: string,
  minHeight?: number,
}

const ScrollBodyBackground = ({ tenant, minHeight = 0 }: IProps) => {
  useEffect(() => {
    const { classList } = document.body;
    const topPositionClass = styles[`tenant${ capitalize(tenant) }TopBackgroundColor`];
    const bottomPositionClass = styles[`tenant${ capitalize(tenant) }BottomBackgroundColor`];

    classList.add(topPositionClass);

    const setBackgroundColor = () => {
      if (window.pageYOffset <= minHeight) {
        classList.add(topPositionClass);
        classList.remove(bottomPositionClass);
      } else {
        classList.remove(topPositionClass);
        classList.add(bottomPositionClass);
      }
    };

    window.addEventListener('scroll', setBackgroundColor);

    return () => {
      window.removeEventListener('scroll', setBackgroundColor);
      classList.remove(
        bottomPositionClass,
        topPositionClass,
      );
    };
  }, [tenant]);

  return <></>;
};

export default ScrollBodyBackground;
