import { useState, useEffect } from 'react';
import UserPermission from '@/users/domain/UserPermission';
import { SessionService } from '@/users/application/Session/SessionService';
import { useDIContext } from '@/Framework/DI/DIContext';

export default function useSession() {
  const { container } = useDIContext();
  const [, setState] = useState({});
  const forceUpdate = () => setState({});
  const sessionService: SessionService = container.get(SessionService);

  useEffect(() => {
    sessionService.handleUpdate(forceUpdate);
  }, [sessionService.currentSession]);

  return {
    initialized: sessionService.initialized,
    session: sessionService.currentSession,
    getSession: sessionService.getCurrentSession,
    sessionFetching: sessionService.currentSessionFetching,
    currentUser: sessionService.currentUser,
    getCurrentUser: sessionService.getCurrentUser,
    currentUserFetching: sessionService.currentUserFetching,
    currentHost: sessionService.currentHost,
    accessPermissions: sessionService.accessPermissions,
    publicAccess: UserPermission.publicAccess,
    managerAccess: UserPermission.managerAccess,
    investorAccess: UserPermission.investorAccess,
    switchToInvestorAccess: sessionService.switchToInvestorAccess,
    switchToPublicAccess: sessionService.switchToPublicAccess,
    switchToManagerAccess: sessionService.switchToManagerAccess,
    switchToLegacyZeroToleranceAccess: sessionService.switchToLegacyZeroToleranceAccess,
    isZeroToleranceAccess: sessionService.isZeroToleranceAccess,
    checkSession: sessionService.checkSession,
    satisfiesAccessPermissions: sessionService.satisfiesAccessPermissions,
    satisfiesCurrentAccessPermissions: sessionService.satisfiesCurrentAccessPermissions,
    prolongSession: sessionService.prolongSession,
    logout: sessionService.logout,
    invalidateSession: sessionService.invalidateSession,
    isTwoFactorAuthenticationRequired: sessionService.isTwoFactorAuthenticationRequired,
    setIsTwoFactorAuthenticationRequired: sessionService.setIsTwoFactorAuthenticationRequired,
    setSsidCookie: sessionService.setSsidCookie,
    getViewSsid: sessionService.getViewSsid,
    clearViewSsid: sessionService.clearViewSsid,
    bindViewSsid: sessionService.bindViewSsid,
    getViewerId: sessionService.getViewerId,
    getFingerprint: sessionService.getFingerprint,
    getUserAgent: sessionService.getUserAgent,
  };
}
