import { matchPath, generatePath } from 'react-router';
import config from '@/Framework/config';

export default function scrubRoute(requestUrl) {
  const urlParsed = new URL(requestUrl);

  config.log.routeScrubber.forEach((routeScrubConfig) => {
    const [path, scrubConfig = {}] = routeScrubConfig;
    const match = matchPath(urlParsed.pathname, {
      path,
      exact: true,
    });

    if (!match || !match.params) {
      return;
    }
    Object.keys(scrubConfig).forEach((key) => {
      if (!(key in match.params)) {
        return;
      }
      if (typeof scrubConfig[key] === 'function') {
        match.params[key] = scrubConfig[key](match.params[key]);
      } else {
        match.params[key] = '***';
      }
    });
    requestUrl = new URL(generatePath(path, match.params), urlParsed.href);
  });

  return requestUrl.href;
}
