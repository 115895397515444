import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import { Subscription } from '@dealroadshow/socket-frontend-sdk';

import Request from '@/Framework/api/Rpc/Request';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import SocketClient from '@/evercall/application/DI/Socket/Client';

import { IRoom } from '@/evercall/domain/vo/dashboard/Room';
import { IParticipant } from '@/evercall/domain/vo/dashboard/Participant';
import {
  DashboardEventType,
  DashboardBindOaccEventType,
  IDashboardEvent,
  TDashboardBindOaccEvent,
} from '@/evercall/domain/vo/dashboard/Event';
import { TDashboardSocketEventsHandlers } from '@/evercall/application/dashboard/interfaces';

@Dependencies(SocketClient, RpcDispatcher)
class DashboardRepository {
  constructor(
    private socket: typeof SocketClient,
    private rpc: typeof RpcDispatcher,
    private dashboardSubscription: Subscription,
  ) {
  }

  getRoomMetadata = async (
    payload: {
      chatId: string,
      ssid: string,
    },
  ): Promise<IRoom> => {
    const { chatId, ssid } = payload;
    const response = await this.rpc.call<RpcSuccess>(new Request('evercall.chat.get_room', { chatId }, { ssid }));
    return response.getResult().payload;
  };

  subscribeToDashboard = async (
    payload: {
      chatId: string,
      contactId: number,
      ssid: string,
      participantId: string,
    } & TDashboardSocketEventsHandlers,
  ): Promise<void> => {
    const {
      chatId,
      ssid,
      contactId,
      participantId,
      setDashboardSocketEventData,
      setDashboardBindOaccSocketEvent,
    } = payload;
    this.dashboardSubscription = await this.socket.subscribe(
      new Request('evercall.chat', { chatId, contactId }, { ssid, participantId }),
    );

    [
      DashboardEventType.ParticipantPostedMessage,
      DashboardEventType.ParticipantPostedMessageToModerators,
      DashboardEventType.ParticipantPostedMessageToSpeaker,
      DashboardEventType.InvestorPostedQuestion,
      DashboardEventType.ParticipantJoined,
      DashboardEventType.ParticipantLeft,
      DashboardEventType.DashboardRenamed,
    ].forEach(
      (eventType: DashboardEventType) => this.dashboardSubscription.on(
        eventType,
        (
          { params: { payload } }: { params: { payload: IDashboardEvent } },
        ) => setDashboardSocketEventData(payload),
      ),
    );

    [
      DashboardBindOaccEventType.LiveConferenceBound,
      DashboardBindOaccEventType.LiveConferenceUnbound,
    ].forEach(
      (eventType: DashboardBindOaccEventType) => this.dashboardSubscription.on(
        eventType,
        (
          { params: { payload } }: { params: { payload: TDashboardBindOaccEvent } },
        ) => setDashboardBindOaccSocketEvent([payload, eventType]),
      ),
    );
  };

  unsubscribeFromDashboard = () => {
    if (this.dashboardSubscription instanceof Subscription) {
      return this.dashboardSubscription.cancel();
    }
    return Promise.resolve();
  };

  getParticipants = async (
    payload: {
      ssid: string,
      roomId: string,
    },
  ): Promise<{ speakers: Array<IParticipant>, operators: Array<IParticipant> }> => {
    const { roomId, ssid } = payload;
    const response = await this.rpc.call<RpcSuccess>(new Request('evercall.chat.get_participants', { roomId }, { ssid }));
    return response.getResult().payload;
  };

  getEventsOfAllTypes = async (
    payload: {
      ssid: string,
      roomId: string,
      page: number,
      perPage: number,
    },
  ): Promise<{ data: Array<IDashboardEvent>, totalCount: number }> => {
    const { ssid, ...restPayload } = payload;
    const response = await this.rpc.call<RpcSuccess>(new Request(
      'evercall.chat.get_public_events',
      {
        ...restPayload,
        sortBy: 'occurredAt',
        sortOrder: 'desc',
      },
      { ssid },
    ));
    return response.getResult().payload;
  };

  getQuestionEvents = async (
    payload: {
      ssid: string,
      roomId: string,
      page: number,
      perPage: number,
    },
  ): Promise<{ data: Array<IDashboardEvent>, totalCount: number }> => {
    const { ssid, ...restPayload } = payload;
    const response = await this.rpc.call<RpcSuccess>(new Request(
      'evercall.chat.get_question_events',
      {
        ...restPayload,
        sortBy: 'occurredAt',
        sortOrder: 'desc',
      },
      { ssid },
    ));
    return response.getResult().payload;
  };

  getPrivateMessageEvents = async (
    payload: {
      ssid: string,
      roomId: string,
      page: number,
      perPage: number,
    },
  ): Promise<{ data: Array<IDashboardEvent>, totalCount: number }> => {
    const { ssid, ...restPayload } = payload;
    const response = await this.rpc.call<RpcSuccess>(new Request(
      'evercall.chat.get_private_events',
      {
        ...restPayload,
        sortBy: 'occurredAt',
        sortOrder: 'desc',
      },
      { ssid },
    ));
    return response.getResult().payload;
  };

  sendMessage = async (
    payload: {
      ssid: string,
      draftId: string,
      roomId: string,
      message: string,
    },
  ): Promise<null> => {
    const { ssid, ...restPayload } = payload;
    const response = await this.rpc.call<RpcSuccess>(
      new Request('evercall.chat.send_message_to_moderators_and_speakers', restPayload, { ssid }),
    );
    return response.getResult().payload;
  };

  sendPrivateMessageToModerators = async (
    payload: {
      ssid: string,
      draftId: string,
      roomId: string,
      message: string,
    },
  ): Promise<null> => {
    const { ssid, ...restPayload } = payload;
    const response = await this.rpc.call<RpcSuccess>(
      new Request('evercall.chat.send_message_to_moderators', restPayload, { ssid }),
    );
    return response.getResult().payload;
  };

  sendPrivateMessage = async (
    payload: {
      ssid: string,
      draftId: string,
      participantId: string,
      roomId: string,
      message: string,
    },
  ): Promise<null> => {
    const { ssid, ...restPayload } = payload;
    const response = await this.rpc.call<RpcSuccess>(
      new Request('evercall.chat.send_message_to_speaker', restPayload, { ssid }),
    );
    return response.getResult().payload;
  };

  updateRoomName = async (
    payload: {
      ssid: string,
      name: string,
      roomId: string,
    },
  ): Promise<null> => {
    const { ssid, ...restPayload } = payload;
    const response = await this.rpc.call<RpcSuccess>(new Request('evercall.chat.update_room', restPayload, { ssid }));
    return response.getResult().payload;
  };

  forceLogoutFromDashboard = async (
    payload: {
      dashboardId: string,
      corporateEmail: string,
    },
  ): Promise<null> => {
    const response = await this.rpc.call<RpcSuccess>(new Request('evercall.dashboard.exit', payload));
    return response.getResult().payload;
  };
}

export default DashboardRepository;
