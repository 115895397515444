import { ISession } from '@/users/domain/session/vo/Session';
import { IUser } from '@/users/domain/vo/User';

export class InitSessionState {
  ssid?: string = '';

  initialized: boolean = false;

  currentSession?: ISession = null;

  currentUser?: IUser = null;
}
