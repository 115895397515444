import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import CondorRpcDispatcher from '@/condor/application/DI/Rpc/HttpDispatcher';
import JsonRpcDispatcher, { Success } from '@dealroadshow/json-rpc-dispatcher';
import CookieRepository from '@/Framework/browser/storage/CookieRepository';
import { CLOSED_HEADER_BANNERS_COOKIE_NAME } from '@/Framework/UI/Organisms/HeaderBanner/contants';

@Dependencies(CondorRpcDispatcher, CookieRepository)
class BannersRepository {
  /**
   *
   * @param {Dispatcher} condorRpcDispatcher
   */
  private readonly condorRpc: JsonRpcDispatcher;

  private readonly cookiesRepository: CookieRepository;

  constructor(condorRpcDispatcher, cookieRepository) {
    this.condorRpc = condorRpcDispatcher;
    this.cookiesRepository = cookieRepository;
  }

  /**
   * @param {Object} payload
   * @return {Object}
   */
  getBannerByTenant = async (payload = {}) => {
    let request = new Request('condor.banner.get_banner_by_tenant', payload);
    let response = await this.condorRpc.call<Success>(request);

    return response.getResult().payload;
  };

  /**
   * @param {Object} payload
   * @return {Object}
   */
  list = async (payload = {}) => {
    let request = new Request('condor.banner.get_banners', payload);
    let response = await this.condorRpc.call<Success>(request);

    return response.getResult().payload;
  };

  /**
   * @param {Object} payload
   * @returns {Object}
   */
  positionsList = async (payload = {}) => {
    let request = new Request('condor.banner.get_positions', payload);
    let response = await this.condorRpc.call<Success>(request);
    return response.getResult().payload;
  };

  /**
   *  Create banner data form values
   *
   * @param {object} payload
   * @returns {Promise<object>}
   */
  create = async (payload = {}) => {
    const req = new Request('condor.banner.create_banner', payload);
    const response = await this.condorRpc.call<Success>(req);

    return response.getResult().payload;
  };

  /**
   *  Update banner data form values
   *
   * @param {object} payload
   * @returns {Promise<object>}
   */
  update = async (payload = {}) => {
    const req = new Request('condor.banner.update_banner', payload);
    const response = await this.condorRpc.call<Success>(req);

    return response.getResult().payload;
  };

  /**
   * delete banner data form values
   *
   * @param {object} payload
   * @returns {Promise<object>}
   */
  delete = async (payload = {}) => {
    const req = new Request('condor.banner.delete_banner', payload);
    const response = await this.condorRpc.call<Success>(req);

    return response.getResult().payload;
  };

  /**
   * get closed banners array
   *
   * @returns {Array}
   */
  getClosedBanners = () => this.cookiesRepository.get(CLOSED_HEADER_BANNERS_COOKIE_NAME)?.split(',') || [];

  /**
   * save closed banner to cookie
   *
   * @param {string} hash
   */
  setClosedBanners = (hash) => {
    const closedBanners = this.getClosedBanners();
    if (closedBanners.length >= 5) {
      closedBanners.shift();
      closedBanners.push(hash);
    } else {
      closedBanners.push(hash);
    }
    this.cookiesRepository.set(CLOSED_HEADER_BANNERS_COOKIE_NAME, closedBanners, {
      domain: '',
      hostOnly: true,
      expires: 365,
    });
  };
}

export default BannersRepository;
