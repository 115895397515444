import isServer from '@/Framework/Router/Next/isServer';

export const base64Encode = (string: string): string => {
  try {
    return isServer()
      ? Buffer.from(string).toString('base64')
      : btoa(string);
  } catch (e) {
    return '';
  }
};
