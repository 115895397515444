import { SessionService } from '@/users/application/Session/SessionService';
import Container from '@/Framework/DI/Container';

export async function checkSessionWithZeroTolerance(container: Container) {
  const sessionService: SessionService = container.get(SessionService);
  if (!sessionService.isZeroToleranceAccess()) {
    return sessionService.checkSession({
      logoutParams: { autoLogout: true, error: true },
      clearCache: true,
    });
  }
  return sessionService.logout();
}
