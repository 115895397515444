import TenantConfig from '@/Framework/Tenant/TenantConfig';
import isServer from '@/Framework/Router/Next/isServer';

/**
 * Checks whether to use proxy for requests.
 *
 * @return {boolean}
 */
// No CORS on server so we are good here
export const shouldUseProxy = () => !isServer() && TenantConfig.fromHostname().useApiProxy;
export default shouldUseProxy;
