import config from '@/Framework/config';
import URL from '@/finsight/Router/urlConstants';
import buildUrl from '@/Framework/url/helpers/buildUrl';
import shouldUseInternalNetwork from '@/Framework/Router/Next/shouldUseInternalNetwork';

export default {
  getUrl(...uris: (string | string[])[]): string {
    return buildUrl(config.protocol, config.tenant.finsight.hostname, ...uris);
  },

  getPropagateCallbackUrl(url: string): string {
    const callbackUrl = this.getUrl(url);
    return encodeURIComponent(this.getApiUrl(`/propagate?callbackUrl=${ encodeURIComponent(callbackUrl) }`));
  },

  getApiUrl(uris: string | string[] = []): string {
    return this.getUrl('/api', uris);
  },

  getUploadUrl(): string {
    return this.getApiUrl('/files');
  },

  getImportTemplateUrl(uri: string): string {
    return this.getApiUrl([
      '/files/bulk/import',
      uri,
    ]);
  },

  getDealImportTemplateUrl(): string {
    return this.getImportTemplateUrl('?template=deal');
  },

  getCompanyImportTemplateUrl(): string {
    return this.getImportTemplateUrl('?template=company');
  },

  getBondImportTemplateUrl(): string {
    return this.getImportTemplateUrl('?template=bi');
  },

  getRpcUrl(): string {
    if (shouldUseInternalNetwork()) {
      return this.getInternalRpcUrl();
    }

    return `${ config.tenant.finsight.api.external }/rpc`;
  },

  getInternalRpcUrl(): string {
    return `${ config.tenant.finsight.api.internal.finsightWeb }/rpc`;
  },

  getTermsOfUseUrl(): string {
    return this.getUrl(URL.POLICIES_TERMS);
  },

  getPrivacyPolicyUrl(): string {
    return this.getUrl(URL.POLICIES_PRIVACY);
  },

  getComplianceUrl(): string {
    return this.getUrl(URL.POLICIES_COMPLIANCE);
  },

  getSecurityPolicyUrl(): string {
    return this.getUrl(URL.POLICIES_SECURITY);
  },

  getContactUsUrl(): string {
    return this.getUrl(URL.CONTACT);
  },

  getCareersUrl(): string {
    return this.getUrl(URL.CAREERS);
  },

  getAboutUrl(): string {
    return this.getUrl(URL.ABOUT);
  },

  getOurProductsUrl(): string {
    return this.getUrl(URL.SOLUTIONS);
  },

  getFeaturesUrl(): string {
    return this.getUrl(URL.FEATURES);
  },

  getPricingPageUrl(): string {
    return this.getUrl(URL.PRICING);
  },

  getBondsInventoryABSUrl(): string {
    return this.getUrl(`${ URL.BONDS_INVENTORIES }-abs`);
  },

  getTraceUrl(): string {
    return this.getUrl(`${ URL.TRACE }-abs`);
  },

  getProductLoanLevelUrl(): string {
    return this.getUrl(URL.PRODUCT_US_ABS_EE);
  },

  getPortfolioMonitorUrl(): string {
    return this.getUrl(URL.PORTFOLIO_MONITOR);
  },

  getProductPipelineUrl(): string {
    return this.getUrl(URL.PRODUCT_US_ABS_PIPELINE);
  },

  getPublicFileUrl(filename: string): string {
    let folder = 'image';

    if (filename.includes('.pdf')) {
      folder = 'pdf';
    }

    return this.getApiUrl(`file/public/${ folder }/${ filename }`);
  },
};
