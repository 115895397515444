import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import UsersRpcDispatcher from '@/users/application/DI/Rpc/HttpDispatcher';

@Dependencies(UsersRpcDispatcher)
class TwoFactorRepository {
  private usersRpc: typeof UsersRpcDispatcher;

  constructor(usersRpc) {
    this.usersRpc = usersRpc;
  }

  /**
   * generate token.
   * token is needed while requesting enable two factor auth by phone and turning off two factor verification by phone
   * @param {String} password
   * @return {Promise<*>}
   */
  async generateTwoFactorToken(password) {
    let req = new Request('user.generate_update_token', { password });
    let response = await this.usersRpc.call(req);

    return response.getResult();
  }

  /**
   * generate and send two factor verification code
   * @param {string} deliveryMethod
   * @return {Promise<*>}
   */
  async sendTwoFactorCode(deliveryMethod = '') {
    let req = new Request('two_factor.send_code', { deliveryMethod });
    let response = await this.usersRpc.call(req);

    return response.getResult();
  }

  /**
   * turn off two factor authorization by text massages.
   * @param {String} updateToken
   * @return {Promise<*>}
   */

  async disableMobileTwoFactor(updateToken) {
    let req = new Request('user.mobile_two_factor.disable', { updateToken });
    let response = await this.usersRpc.call(req);

    return response.getResult();
  }

  /**
   * check two factor verification code and enable two factor authorization by text massages.
   * @param {String} code
   * @return {Promise<*>}
   */

  async enableMobileTwoFactor(code) {
    let req = new Request('user.mobile_two_factor.enable', { code });
    let response = await this.usersRpc.call(req);

    return response.getResult();
  }

  /**
   * check two factor verification code
   * @param {string} code
   * @return {Promise<*>}
   */
  async checkTwoFactorCode(code) {
    let req = new Request('two_factor.attempt', { code });
    let response = await this.usersRpc.call(req);

    return response.getResult();
  }

  /**
   * adding mobile phone to receive two factor codes by text messages
   * @param {String} mobilePhone
   * @param {String} updateToken
   * @return {Promise.<*>}
   */

  async addTwoFactorPhone(mobilePhone, updateToken) {
    let req = new Request('user.mobile_phone.add',
      { mobilePhone, updateToken });
    let response = await this.usersRpc.call(req);

    return response.getResult();
  }

  /**
   * generate two factor code for user by support
   * !!!NOTE: this method won't send code to user, just write code into database and return it to FE!!!
   * @param {String|Number} id
   * @return {Promise.<*>}
   */
  generateTwoFactorCode = async (id) => {
    let req = new Request('two_factor.generate_code', { id });
    let response = await this.usersRpc.call(req);

    return response.getResult();
  };
}

export default TwoFactorRepository;
