import { Event } from '@sentry/nextjs';

/**
 * https://sentry.io/organizations/finsight-group-inc/issues/3625249607/?project=5192325
 * https://sentry.io/organizations/finsight-group-inc/issues/3761598558/?project=5192325
 * Looks like this exception is thrown by crawler.
 * This filter is created to mute external TypeError and ReferenceError.
 *
 * @param {Event} event Sentry.Event
 * @returns {boolean}
 */
function hasExternalError(event: Event): boolean {
  const { type, value, stacktrace } = (event.exception?.values || [{}])[0];
  const typeMatches = type === 'TypeError' || type === 'ReferenceError';
  const valueMatches = /is not (a function|defined)$/.test(value);
  const fileNameMatches = stacktrace?.frames?.[0]?.filename === '<anonymous>';
  const absPathMatches = stacktrace?.frames?.[0]?.abs_path === '<anonymous>';

  return typeMatches && valueMatches && fileNameMatches && absPathMatches;
}

export default hasExternalError;
