import React, { useEffect } from 'react';
import cn from 'classnames';
import NotificationType from './constants';
import { Icon, IconType } from '@dealroadshow/uikit';
import styles from './notifications.scss';

export interface INotificationProps {
  type?: NotificationType,
  title?: React.ReactNode,
  message: React.ReactNode | string,
  timeOut?: number,
  onClick?: () => void,
  onHide?: () => void,
  iconType?: IconType,
  className?: string,
}

const Notification = ({
  type = NotificationType.INFO,
  message = null,
  timeOut = 10000,
  title = null,
  iconType = IconType.info,
  className,
  onClick = () => {},
  onHide = () => {},
}: INotificationProps) => {
  useEffect(() => {
    let timer;
    if (timeOut !== 0) {
      timer = setTimeout(handleHide, timeOut);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timeOut]);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    handleHide();
  };

  const handleHide = () => {
    if (onHide) {
      onHide();
    }
  };

  const notificationCls = cn(
    className,
    { [styles.info]: type === NotificationType.INFO },
    { [styles.success]: type === NotificationType.SUCCESS },
    { [styles.warning]: type === NotificationType.WARNING },
    { [styles.error]: type === NotificationType.ERROR },
  );

  if (typeof message === 'string' || message instanceof String) {
    // @ts-ignore
    message = <span dangerouslySetInnerHTML={ { __html: message } } />;
  }

  return (
    <div className={ styles.wrp }>
      <div className={ notificationCls } onClick={ handleClick }>
        <div className={ styles.message } role="alert">
          { title && (
            <h4 className={ styles.title }>
              { iconType && (
                <Icon
                  type={ iconType }
                  className={ styles.icon }
                />
              ) }
              { title }
            </h4>
          ) }
          { message }
        </div>
      </div>
    </div>
  );
};

export default Notification;
