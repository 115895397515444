import cn from 'classnames';
import { CONTENT_WRP_ID } from './constants';
import styles from './contentWrp.scss';

interface IProps {
  children: React.ReactNode,
  className?: string,
}

function ContentWrp(props: IProps) {
  return (
    <div
      id={ CONTENT_WRP_ID }
      className={ cn(styles.wrp, props.className) }
    >
      { props.children }
    </div>
  );
}

export default ContentWrp;
