import * as actionTypes from './actionTypes';

const initialState = {
  isFetching: false,
  data: [],
};

export default function loginsByUSContacts(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_LOGINS_BY_US_CONTACTS:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.GET_LOGINS_BY_US_CONTACTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.payload,
      };
    case actionTypes.GET_LOGINS_BY_US_CONTACTS_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.LOGINS_BY_US_CONTACTS_RESET:
      return initialState;
    default:
      return state;
  }
}
