class SessionStorageRepository {
  /**
   * Save data to session storage
   *
   * @param {string} key
   * @param {*} value
   *
   * @return SessionStorageRepository
   */
  setItem(key, value) {
    sessionStorage.setItem(key, value);

    return this;
  }

  /**
   * Get item from session storage
   *
   * @param {string} key
   * @return {*|SVGPoint|SVGNumber|string|SVGTransform|SVGLength}
   */
  getItem(key) {
    return sessionStorage.getItem(key);
  }

  /**
   *
   * @param {string} key
   * @return {*}
   *
   * @return SessionStorageRepository
   */
  removeItem(key) {
    sessionStorage.removeItem(key);

    return this;
  }

  /**
   * Clear session storage
   *
   * @return SessionStorageRepository
   */
  clear() {
    sessionStorage.clear();

    return this;
  }
}

export default SessionStorageRepository;
