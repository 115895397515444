import debounce from 'lodash/debounce';
import { SYNTHETIC_ACTIVITY_EVENT } from './emitSyntheticActivity';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';

/**
 * Track user activity and inactivity and fire callbacks on this state is changed
 *
 * @param {Number} time
 * @param {Function|[Function]} onIdle
 * @param {Function|[Function]} onAlive
 * @param {Object} eventTarget
 */
function trackIdle(time, onIdle, onAlive, eventTarget = window) {
  if (!Array.isArray(onIdle)) {
    onIdle = [onIdle];
  }
  if (!Array.isArray(onAlive)) {
    onAlive = [onAlive];
  }

  /**
   * If app in idle state now
   *
   * @type {boolean}
   */
  let isIdle = false;

  /**
   * Timer
   */
  let timer;

  /**
   * Time when window became idle last time
   */
  let becameIdleTime;

  /**
   * Set idle timer
   */
  let setTimer = () => {
    timer = setTimeout(() => {
      becameIdleTime = new Date();
      isIdle = true;
      onIdle.forEach((callback) => callback(0));
    }, time);
  };

  /**
   * Reset idle timer
   */
  let resetTimer = () => {
    becameIdleTime = undefined;
    clearTimeout(timer);
    setTimer();
  };

  /**
   * Check idle timing
   *
   * @return {boolean}
   */
  let checkIdleTiming = () => {
    if (becameIdleTime) {
      let idleDiff = differenceInMilliseconds(new Date(), becameIdleTime);

      if (idleDiff >= time) {
        becameIdleTime = undefined;
        onIdle.forEach((callback) => callback(idleDiff - time));
        isIdle = true;

        return false;
      }
    }

    return true;
  };

  /**
   * Became alive from idle
   */
  let becameAlive = (trigger) => debounce(() => {
    checkIdleTiming();
    resetTimer();
    if (isIdle) {
      isIdle = false;
      onAlive.forEach((callback) => callback(trigger));
    }
  }, 100, { maxWait: 200 })();

  /**
   * Add listeners for user activity
   */
  let addEventListener = () => {
    eventTarget.addEventListener(SYNTHETIC_ACTIVITY_EVENT, () => becameAlive(SYNTHETIC_ACTIVITY_EVENT));
    eventTarget.addEventListener('load', () => becameAlive('load'));
    eventTarget.addEventListener('mousemove', () => becameAlive('mousemove'));
    eventTarget.addEventListener('mousedown', () => becameAlive('mousedown'));
    eventTarget.addEventListener('touchstart', () => becameAlive('touchstart'));
    eventTarget.addEventListener('click', () => becameAlive('click'));
    eventTarget.addEventListener('scroll', () => becameAlive('scroll'));
    eventTarget.addEventListener('keypress', () => becameAlive('keypress'));
    eventTarget.addEventListener('focus', () => becameAlive('focus'));

    eventTarget.addEventListener('blur', () => {
      becameIdleTime = new Date();
    });
  };

  setTimer();
  addEventListener();
}

export default trackIdle;
