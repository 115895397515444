import React, { useEffect, useState } from 'react';
import NotificationManager from './NotificationManager';
import Notifications, { INotificationsProps } from './Notifications';

interface IProps {
  enterTimeout?: number,
  exitTimeout?: number,
  containerClassName?: string,
  notificationClassName?: string,
}

const NotificationContainer = ({
  enterTimeout = 400,
  exitTimeout = 400,
  containerClassName,
  notificationClassName,
}: IProps) => {
  const [notifications, setNotifications] = useState(NotificationManager.getNotificationsList());

  useEffect(() => {
    const handleStoreChange = (notifications: INotificationsProps[]) => {
      setNotifications([...notifications]);
    };
    NotificationManager.addChangeListener(handleStoreChange);
    return () => {
      NotificationManager.removeChangeListener(handleStoreChange);
    };
  }, []);

  const handleHide = (notification: INotificationsProps) => {
    NotificationManager.remove(notification);
  };

  return (
    <Notifications
      enterTimeout={ enterTimeout }
      exitTimeout={ exitTimeout }
      notifications={ notifications }
      onHide={ handleHide }
      containerClassName={ containerClassName }
      notificationClassName={ notificationClassName }
    />
  );
};

export default NotificationContainer;
