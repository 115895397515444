import * as esCookies from 'es-cookie';
import { Dependencies } from 'constitute';
import config from '@/Framework/config';
import isServer from '@/Framework/Router/Next/isServer';
import Container from '@/Framework/DI/Container';
import ClientCookie from '@/Framework/Router/Next/cookie/ClientCookie';

const cookies = { ...esCookies };
@Dependencies(Container)
class CookieRepository {
  serverCookieString = '';

  // @ts-ignore
  #serverCookie = '';

  constructor(container) {
    if (isServer()) {
      const cookieService = container.get(ClientCookie);
      this.#serverCookie = cookieService.cookie || '';
    }
  }

  /**
   * Set cookie
   *
   * @param {String} key
   * @param {String} value
   * @param {?Object} attributes
   * @return {*}
   */
  set(key, value, attributes = {}) {
    if (isServer()) {
      this.serverCookieString = cookies.encode(key, value, { path: '/', ...attributes });
      return this.serverCookieString;
    }
    // @ts-ignore
    return cookies.set(key, value, {
      domain: `.${ config.host }`,
      ...attributes,
      secure: config.cookie.secure,
    });
  }

  /**
   * Get cookie
   *
   * @param {String} key
   * @return {*}
   */
  get(key) {
    if (isServer()) {
      return this.#serverCookie[key];
    }
    return cookies.get(key);
  }

  /**
   * Get all cookie
   *
   * @return {*}
   */
  getAll() {
    if (isServer()) {
      return this.#serverCookie;
    }
    return cookies.getAll();
  }

  /**
   * Delete cookie
   *
   * @param {String} key
   * @param {?Object} attributes
   * @return {*}
   */
  remove(key, attributes = {}) {
    return cookies.remove(key, {
      ...attributes,
      domain: `.${ config.host }`,
    });
  }

  /**
   * Delete cookie from current domain
   *
   * @param {String} key
   * @return {*}
   */
  removeFromCurrentDomain(key) {
    return cookies.remove(key);
  }
}

export default CookieRepository;
