import '@/Framework/browser/polyfill';
import { AppProps } from 'next/app';
import React, { useEffect, useState } from 'react';
import { Store } from 'redux';
import Head from 'next/head';
import GlobalStyles, { styles } from '@/dealroadshow/ui/common/GlobalStyles';
import Container from '@/Framework/DI/Container';
import Bootstrap, { wrapper } from '@/Framework/Bootstrap';
import reducer from '@/dealroadshow/reducer';
import ErrorBoundary from '@/Framework/ErrorHandling/ErrorBoundary';
import ApplicationFallback from '@/Framework/ErrorHandling/ErrorBoundary/ApplicationFallback';
import ContentWrp from '@/Framework/UI/Templates/ContentWrp';
import { Provider } from 'react-redux';
import { MemoryRouter } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import GlobalProps from '@/Framework/Router/Next/GlobalProps';
import { IGlobalProps } from '@/Framework/Router/Next/withGlobalProps';

const App = ({ Component, pageProps, container, store }: AppProps<IGlobalProps> & {
  container: Container,
  store: Store,
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const Router = isMounted ? BrowserRouter : MemoryRouter;

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <>
      {
        isMounted && (
          <GlobalStyles>
            <Head>
              <title>Deal Roadshow</title>
              <meta charSet="utf-8" />
              <meta name="referrer" content="no-referrer-when-downgrade" />
              <meta
                httpEquiv="Cache-Control"
                content="no-store, no-cache, must-revalidate, proxy-revalidate, max-age=0"
              />
              <meta httpEquiv="Pragma" content="no-store" />
              <meta httpEquiv="Expires" content="0" />
              <meta
                content="initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no"
                name="viewport"
              />
              <meta name="HandheldFriendly" content="true" />
            </Head>
            { /* @ts-ignore */ }
            <ErrorBoundary fallback={ ApplicationFallback }>
              <Router>
                <Bootstrap
                  container={ container }
                  session={ pageProps?.session }
                  store={ store }
                  cookies={ pageProps?.cookies }
                >
                  <GlobalProps { ...pageProps }>
                    <ContentWrp className={ styles.body }>
                      <Provider store={ store }>
                        <Component { ...pageProps } />
                      </Provider>
                    </ContentWrp>
                  </GlobalProps>
                </Bootstrap>
              </Router>
            </ErrorBoundary>
          </GlobalStyles>
        )
      }
    </>
  );
};

export default wrapper(reducer).withRedux(App);
