import findKey from 'lodash/findKey';
import config from '@/Framework/config';
import { ITenantConfig } from '@/Framework/Tenant/vo/TenantConfig';
import isServer from '@/Framework/Router/Next/isServer';

/**
 * This class represents config of tenant. It includes all properties you can find in config.tenant[{code}]
 */
class TenantConfig {
  constructor(tenantConfig) {
    // Copy all properties to this object
    Object.assign(this, tenantConfig);
  }

  // TODO: Type casting is used now. In the future we need to bring every tenant config to the same ITenantConfig type.
  private static tenantConfig: {
    [key: string]: ITenantConfig,
  } = config.tenant as unknown as {
    [key: string]: ITenantConfig,
  };

  static fromCode(code: string) {
    const tenantKey = findKey(this.tenantConfig, (tenant) => tenant.code === code);
    if (typeof tenantKey === 'undefined') {
      throw new Error(`Tenant ${ code } not found`);
    }
    return new TenantConfig(this.tenantConfig[tenantKey]) as ITenantConfig;
  }

  static fromHostname(hostname: string = isServer() ? undefined : window.location.hostname) {
    if (!this.exists(hostname)) {
      throw new Error(`Tenant not found, hostname: ${ hostname }`);
    }
    return new TenantConfig(
      this.tenantConfig[findKey(this.tenantConfig, (tenant) => tenant.hostname === hostname)],
    ) as ITenantConfig;
  }

  static fromObject(object: ITenantConfig) {
    return new TenantConfig(object) as ITenantConfig;
  }

  /**
   * Checks whether tenant exists for specific hostname
   * @param {String} hostname
   * @return {boolean}
   */
  static exists(hostname: string = isServer() ? undefined : window.location.hostname) {
    return !!this.tenantConfig[findKey(this.tenantConfig, (tenant) => tenant.hostname === hostname)];
  }
}

export default TenantConfig;
