import { IParticipant } from './Participant';
import { IDashboardUser } from '@/evercall/domain/vo/dashboard/User';

export enum DashboardLocalEventType {
  DraftMessage = 'draft_message',
  DraftMessageToModerators = 'draft_message_to_moderators',
  DraftMessageToSpeaker = 'draft_message_to_speaker',
}

export enum DashboardEventType {
  ParticipantJoined = 'participant.joined',
  ParticipantLeft = 'participant.left',
  DashboardRenamed = 'chat.renamed',
  ParticipantPostedMessage = 'participant.posted_moderators_and_speakers_message',
  ParticipantPostedMessageToModerators = 'participant.posted_moderators_message',
  ParticipantPostedMessageToSpeaker = 'participant.posted_speaker_message',
  InvestorPostedQuestion = 'investor.posted_question',
}

/*
 These events occur in the same socket where the DashboardEventType events occur, but they are separated because
 the payload type is different (see TDashboardBindOaccEvent below)
 */
export enum DashboardBindOaccEventType {
  LiveConferenceBound = 'dashboard_live_conference.bound',
  LiveConferenceUnbound = 'dashboard_live_conference.unbound',
}

// This is the only event that occurs in the OACC Dashboard socket
export enum OaccDashboardEventType {
  LiveConferenceUpdated = 'dashboard_live_conference.updated',
}

interface IDashboardRenameEventMeta {
  chat: {
    oldName: string,
    newName: string,
  },
}

interface IPostMessageEventMeta {
  draftId: string,
  message: string,
  recipient?: IDashboardUser, // exists only for private message to speaker
}

export type TDashboardBindOaccEvent = (
  { conferenceId: string } // oacc conference bound
  | null // oacc conference unbound
);

export interface IInvestorQuestionMeta {
  investor: IDashboardUser,
  question: string,
}

export type IDashboardEvent = {
  id: string,
  participant: IParticipant | null,
  type: DashboardEventType | DashboardLocalEventType,
  meta: IDashboardRenameEventMeta | IPostMessageEventMeta | IInvestorQuestionMeta | null,
  occurredAt: number,
};
