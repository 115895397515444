import { resendActivationEmail, resendVerificationEmail } from '@/users/application/actions/activation/actions';
import { forceLogoutFromEvercallDashboard } from '@/evercall/application/dashboard/helpers';
import {
  ACTIVATION_EMAIL_RESEND_CALLBACK,
  VERIFICATION_EMAIL_RESEND_CALLBACK,
  EVERCALL_DASHBOARD_FORCE_LOGOUT_CALLBACK,
} from '@/Framework/Message/callbackTypes';

const callbackMapping = {
  [VERIFICATION_EMAIL_RESEND_CALLBACK]: resendVerificationEmail,
  [ACTIVATION_EMAIL_RESEND_CALLBACK]: resendActivationEmail,
  [EVERCALL_DASHBOARD_FORCE_LOGOUT_CALLBACK]: forceLogoutFromEvercallDashboard,
};

export default callbackMapping;
