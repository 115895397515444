import * as actionTypes from './actionTypes';

const initialState = {
  collection: [],
  isFetching: false,
};

export default function dialIn(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_DIAL_IN_PHONES:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.REQUEST_DIAL_IN_PHONES_SUCCESS:
      return {
        ...state,
        collection: action.payload,
        isFetching: false,
      };
    case actionTypes.REQUEST_DIAL_IN_PHONES_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
}
