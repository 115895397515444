import * as actionTypes from './actionTypes';

const initialState = {
  Link: {
    isFetching: false,
    collection: [],
  },
  CommercialMedia: {
    isFetching: false,
    collection: [],
  },
  File: {
    isFetching: false,
    collection: [],
  },
};

export default function componentsStats(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_COMPONENTS_STATS:
      return {
        ...state,
        [action.payload.componentType]: {
          ...state[action.payload.componentType],
          isFetching: true,
        },
      };
    case actionTypes.REQUEST_COMPONENTS_STATS_SUCCESS:
      return {
        ...state,
        [action.payload.componentType]: {
          isFetching: false,
          ...action.payload.response,
        },
      };
    case actionTypes.REQUEST_COMPONENTS_STATS_ERROR:
      return {
        ...state,
        [action.payload.componentType]: {
          ...state[action.payload.componentType],
          isFetching: false,
        },
      };
    case actionTypes.RESET_COMPONENTS_STATS:
      return initialState;
    default:
      return state;
  }
}
