import { useEffect } from 'react';
import TenantConfig from '@/Framework/Tenant/TenantConfig';
import isDefined from '@/Framework/dataHelpers/isDefined';
import Logger from '@/Framework/browser/log/Logger';
import isServer from '@/Framework/Router/Next/isServer';

const getTrackingIdGoogleTagManager = () => {
  const tenantConfig = TenantConfig.fromHostname();

  if (!tenantConfig) {
    return undefined;
  }

  if (!tenantConfig.gtm) {
    Logger.error('GTM part of tenant config not found.', { tenantConfig });
  }

  return tenantConfig.gtm.trackingId;
};

const getTemplate = (id) => `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${ id }');`;

const GoogleTagManager = () => {
  useEffect(() => {
    const trackingId = getTrackingIdGoogleTagManager();

    if (isDefined(trackingId) && !isServer()) {
      const openComment = document.createComment('Google Tag Manager');
      const closeComment = document.createComment('End Google Tag Manager');
      const script = document.createElement('script');

      document.head.insertBefore(script, document.head.firstChild);
      document.head.insertBefore(openComment, document.head.firstChild);
      script.parentNode.insertBefore(closeComment, script.nextSibling);

      script.innerHTML = getTemplate(trackingId);
    }
  }, []);

  return null;
};

export default GoogleTagManager;
