import React from 'react';
import cn from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Notification, { INotificationProps } from './Notification';
import styles from './notifications.scss';

export interface INotificationsProps extends INotificationProps {
  id? : string,
}

export interface IProps {
  notifications: INotificationsProps[],
  onHide?: (notification: INotificationProps) => void,
  enterTimeout?: number,
  exitTimeout?: number,
  containerClassName?: string,
  notificationClassName?: string,
}

const Notifications = ({
  notifications,
  onHide = () => {},
  enterTimeout = 400,
  exitTimeout = 400,
  containerClassName: propsContainerClassName,
  notificationClassName: propsNotificationClassName,
}: IProps) => {
  const containerClassName = cn(styles.container, propsContainerClassName, {
    'is-empty': !notifications.length,
  });

  const notificationClassName = propsNotificationClassName || styles.notification;

  const handleHide = (notification: INotificationProps) => {
    if (onHide) {
      onHide(notification);
    }
  };

  return (
    <div className={ containerClassName }>
      <TransitionGroup>
        { notifications.map((notification) => {
          const key = notification.id || new Date().getTime();
          return (
            <CSSTransition
              key={ key }
              timeout={ { enter: enterTimeout, exit: exitTimeout } }
              classNames={ notificationClassName }
            >
              <Notification
                className={ notificationClassName }
                type={ notification.type }
                title={ notification.title }
                message={ notification.message }
                timeOut={ notification.timeOut }
                onClick={ notification.onClick }
                onHide={ () => handleHide(notification) }
                iconType={ notification.iconType }
              />
            </CSSTransition>
          );
        }) }
      </TransitionGroup>
    </div>
  );
};

export default Notifications;
