import get from 'lodash/get';
import createAction from '@/Framework/State/Redux/createAction';
import createAsyncRequest from '@/Framework/State/Redux/createAsyncRequest';

export const defaultActionTypesConfig = {
  withPagination: true,
  withItemsPerPage: true,
  withSort: false,
  withSearch: false,
};

/**
 * @deprecated all the functions exported from 'collectionFactory.js' are deprecated.
 * Please use useFetchCollection() hook instead.
 *
 * @param {String} collectionActionTypeKeyWord
 * @param {Object.<String, Boolean>} [actionTypesConfig]
 * @param {Boolean} [actionTypesConfig.withPagination]
 * @param {Boolean} [actionTypesConfig.withItemsPerPage]
 * @param {Boolean} [actionTypesConfig.withSort]
 * @param {Boolean} [actionTypesConfig.withSearch]
 */
export const getCollectionActionTypes = (collectionActionTypeKeyWord, actionTypesConfig = {}) => {
  const config = { ...defaultActionTypesConfig, ...actionTypesConfig };
  return {
    GET_COLLECTION: `GET_${ collectionActionTypeKeyWord }_COLLECTION`,
    GET_COLLECTION_SUCCESS: `GET_${ collectionActionTypeKeyWord }_COLLECTION_SUCCESS`,
    GET_COLLECTION_ERROR: `GET_${ collectionActionTypeKeyWord }_COLLECTION_ERROR`,
    ...(config.withPagination && {
      PAGINATE: `SET_${ collectionActionTypeKeyWord }_PAGE`,
    }),
    ...(config.withItemsPerPage && {
      ITEMS_PER_PAGE: `SET_${ collectionActionTypeKeyWord }_ITEMS_PER_PAGE`,
    }),
    ...(config.withSort && {
      SORT: `SET_${ collectionActionTypeKeyWord }_SORT`,
    }),
    ...(config.withSearch && {
      SEARCH: `SET_${ collectionActionTypeKeyWord }_SEARCH_QUERY`,
    }),
    RESET: `RESET_${ collectionActionTypeKeyWord }_COLLECTION`,
  };
};

/**
 * @deprecated all the functions exported from 'collectionFactory.js' are deprecated.
 * Please use useFetchCollection() hook instead.
 *
 * @param {Object} actionTypes
 * @param {Function} fetchMethod
 * @param {Object.<String, Function>} [callbacks]
 * @param {Function} [callbacks.responseHandler]
 * @param {Function} [callbacks.onSuccess]
 * @param {Function} [callbacks.onError]
 */
export const getCollectionActions = (actionTypes, fetchMethod, callbacks = {}) => (
  {
    getCollection: (payload) => createAsyncRequest(
      {
        payload,
        method: fetchMethod,
        types: [actionTypes.GET_COLLECTION, actionTypes.GET_COLLECTION_SUCCESS, actionTypes.GET_COLLECTION_ERROR],
        callbacks,
      },
    ),
    ...(actionTypes.PAGINATE && {
      paginate: (payload) => createAction(actionTypes.PAGINATE, payload),
    }),
    ...(actionTypes.ITEMS_PER_PAGE && {
      itemsPerPage: (payload) => createAction(actionTypes.ITEMS_PER_PAGE, payload),
    }),
    ...(actionTypes.SORT && {
      sort: (payload) => createAction(actionTypes.SORT, payload),
    }),
    ...(actionTypes.SEARCH && {
      setSearchQuery: (payload) => createAction(actionTypes.SEARCH, payload),
    }),
    reset: () => createAction(actionTypes.RESET),
  }
);

/**
 * @deprecated all the functions exported from 'collectionFactory.js' are deprecated.
 * Please use useFetchCollection() hook instead.
 *
 * @param {Object} actionTypes
 * @param {Object} [newState]
 */
export const getCollectionReducerInitialState = (actionTypes, newState = {}) => (
  {
    data: [],
    isFetching: false,
    totalCount: 0,
    page: 1,
    perPage: 25,
    ...(actionTypes.SEARCH && {
      searchQuery: '',
    }),
    ...(actionTypes.SORT && {
      sortBy: '',
      sortOrder: '',
    }),
    ...newState,
  }
);

/**
 * @deprecated all the functions exported from 'collectionFactory.js' are deprecated.
 * Please use useFetchCollection() hook instead.
 *
 * @param {Object} actionTypes
 * @param {Object} [newState]
 * @param {Object.<String, Function>} [additionalCases]
 */
export const getCollectionReducer = (actionTypes, newState = {}, additionalCases = {}) => {
  const initialState = getCollectionReducerInitialState(actionTypes, newState);
  return (state = initialState, action) => {
    if (additionalCases[action.type]) {
      return additionalCases[action.type](state, action, initialState);
    }

    switch (action.type) {
      case actionTypes.GET_COLLECTION:
        return {
          ...state,
          isFetching: true,
        };
      case actionTypes.GET_COLLECTION_SUCCESS:
        return {
          ...state,
          isFetching: false,
          data: action.payload.collection,
          totalCount: action.payload.totalCount,
        };
      case actionTypes.GET_COLLECTION_ERROR:
        return {
          ...state,
          isFetching: false,
        };
      case actionTypes.PAGINATE:
        return {
          ...state,
          page: action.payload,
        };
      case actionTypes.ITEMS_PER_PAGE:
        return {
          ...state,
          perPage: action.payload,
          page: 1,
        };
      case actionTypes.SORT:
        return {
          ...state,
          sortBy: action.payload.sortBy,
          sortOrder: action.payload.sortOrder,
          page: 1,
        };
      case actionTypes.SEARCH:
        return {
          ...state,
          searchQuery: action.payload,
        };
      case actionTypes.RESET:
        return initialState;
      default:
        return state;
    }
  };
};

/**
 * @deprecated all the functions exported from 'collectionFactory.js' are deprecated.
 * Please use useFetchCollection() hook instead.
 *
 * @param {String} pathToReducerState
 * @param {Object} [actionTypes]
 */
export const getCollectionSelectors = (pathToReducerState, actionTypes = {}) => (
  {
    getCollectionData: (state) => get(state, `${ pathToReducerState }.data`, []),
    isFetching: (state) => get(state, `${ pathToReducerState }.isFetching`, false),
    getTotalCount: (state) => get(state, `${ pathToReducerState }.totalCount`, 0),
    getPage: (state) => get(state, `${ pathToReducerState }.page`, 1),
    getPerPage: (state) => get(state, `${ pathToReducerState }.perPage`, 25),
    ...(actionTypes.SEARCH && {
      getSearchQuery: (state) => get(state, `${ pathToReducerState }.searchQuery`, ''),
    }),
    ...(actionTypes.SORT && {
      getSortBy: (state) => get(state, `${ pathToReducerState }.sortBy`, ''),
      getSortOrder: (state) => get(state, `${ pathToReducerState }.sortOrder`, ''),
    }),
  }
);
