import * as actionTypes from './actionTypes';

const initialState = {
  isFetching: false,
  collection: [],
};

export default function totalViewTimeByDate(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_TOTAL_VIEW_TIME_BY_DATE:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.REQUEST_TOTAL_VIEW_TIME_BY_DATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.payload,
      };
    case actionTypes.REQUEST_TOTAL_VIEW_TIME_BY_DATE_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.TOTAL_VIEW_TIME_BY_DATE_RESET:
      return initialState;
    default:
      return state;
  }
}
