import * as actionTypes from './actionTypes';

const initialState = {
  collection: [],
  isCollectionFetching: false,
  initialCollection: [],
  item: {},
  itemSettings: {
    timeZone: {
      id: '',
      timeZone: 'America/New_York',
      abbr: 'ET',
    },
  },
  isItemFetching: false,
  totalCount: 0,
  page: 1,
  perPage: 25,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  search: '',
  isBlacklisted: false,
};

export default function data(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_ROADSHOWS:
      return {
        ...state,
        isCollectionFetching: true,
      };
    case actionTypes.REQUEST_ROADSHOW:
      return {
        ...state,
        item: {},
        isItemFetching: true,
      };
    case actionTypes.REQUEST_ROADSHOWS_SUCCESS:
      return {
        ...state,
        ...(action.payload.isInitialCollection ? { initialCollection: action.payload.data } : {}),
        collection: action.payload.data,
        totalCount: action.payload.totalCount,
        isCollectionFetching: false,
      };
    case actionTypes.REQUEST_ROADSHOW_SUCCESS:
      return {
        ...state,
        item: action.payload,
        isItemFetching: false,
      };
    case actionTypes.REQUEST_ROADSHOW_SETTINGS_SUCCESS:
      return {
        ...state,
        itemSettings: action.payload,
      };
    case actionTypes.REQUEST_ROADSHOWS_ERROR:
      return {
        ...state,
        isCollectionFetching: false,
      };
    case actionTypes.REQUEST_ROADSHOW_ERROR:
      return {
        ...state,
        isItemFetching: false,
      };
    case actionTypes.TOGGLE_ROADSHOW_PUBLISHED:
      return {
        ...state,
        collection: action.payload.slice(),
      };
    case actionTypes.ROADSHOWS_SEARCH_CHANGE:
      return {
        ...state,
        search: action.payload.search,
        page: 1,
      };
    case actionTypes.ROADSHOWS_SORT_CHANGE:
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    case actionTypes.ROADSHOWS_PAGE_CHANGE:
      return {
        ...state,
        page: action.payload.page,
      };
    case actionTypes.ROADSHOWS_ITEMS_PER_PAGE_CHANGE:
      return {
        ...state,
        perPage: action.payload.perPage,
        page: 1,
      };
    case actionTypes.ROADSHOWS_RESET:
      const { item, isItemFetching, ...otherProps } = initialState;
      return {
        ...state,
        ...otherProps,
      };
    case actionTypes.RESET_ROADSHOW_ITEM:
      return {
        ...state,
        item: {},
      };
    case actionTypes.RESET_ROADSHOW_SETTINGS:
      return {
        ...state,
        itemSettings: {
          timeZone: {
            id: '',
            timeZone: 'America/New_York',
            abbr: 'ET',
          },
        },
      };
    case actionTypes.GET_APPLICATION_IS_BLACKLISTED:
      return {
        ...state,
        isBlacklisted: action.payload,
      };
    case actionTypes.GET_APPLICATION_IS_BLACKLISTED_ERROR:
      return {
        ...state,
        isBlacklisted: false,
      };
    case actionTypes.TOGGLE_INVESTOR_TARGETING:
      return {
        ...state,
        itemSettings: {
          ...state.itemSettings,
          investorTargetingEnabled: action.payload.investorTargetingEnabled,
        },
      };
    default:
      return state;
  }
}
