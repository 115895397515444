import TenantConfig from '@/Framework/Tenant/TenantConfig';

export interface IFeatureToggleItem {
  cookieName: string,
  isEnabled?: boolean,
}

export interface IFeatureToggle {
  [key: string]: IFeatureToggleItem,
}

export const getFeatureToggleConfig = (hostname = window?.location.hostname) => {
  return TenantConfig.fromHostname(hostname).featureToggle || {};
};

export const getFeatureToggleCalculatedList = (cookies, featuresList): IFeatureToggle => {
  const features = {};
  Object.keys(featuresList).forEach((featureName) => {
    features[featureName] = {
      ...featuresList[featureName],
      isEnabled: !!cookies[featuresList[featureName].cookieName],
    };
  });
  return features;
};
