import ErrorCodeHelper from '@finsight/error-codes';
import { getMessageByErrorCode } from '@/Framework/Message/Mapper/getMessage';
import DmPortalUserRepository from '@/dealroadshow/infrastructure/repository/UserRepository';
import UserRepository from '@/users/infrastructure/repository/UserRepository';
import { NotificationManager } from '@/Framework/Notification';
import Container from '@/Framework/DI/Container';
/**
 * Resend verification email
 *
 * @param {Container} container
 * @param {String} email
 * @param {?String} entryCode
 * @param {?String} roadshowId
 *
 * @returns {Promise<void>}
 */
export async function resendVerificationEmail(container: Container, { email, entryCode = null, roadshowId = null }) {
  const dmPortalUserRepository: DmPortalUserRepository = container.get(DmPortalUserRepository);
  await dmPortalUserRepository.resendVerificationEmail({ email, entryCode, roadshowId });

  NotificationManager.info(getMessageByErrorCode(ErrorCodeHelper.getCodeByName('INVESTOR_VERIFICATION_EMAIL_SENT'), { email }));
}

/**
 * Resend activation email
 *
 * @param {Container} container
 * @param {string} email
 * @param {string} callbackUrl
 * @param {string} tenant
 *
 * @returns {Promise<void>}
 */
export async function resendActivationEmail(container: Container, { email, callbackUrl, tenant }) {
  const userRepository: UserRepository = container.get(UserRepository);
  await userRepository.resendActivationEmail({ email, callbackUrl, tenant });

  NotificationManager.info(getMessageByErrorCode(ErrorCodeHelper.getCodeByName('USER_ACTIVATION_EMAIL_SENT'), { email }));
}
