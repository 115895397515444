const formatUuid = {
  addDashes: (uuid) => {
    if (uuid.length === 32) {
      uuid = uuid.replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, '$1-$2-$3-$4-$5');
    }

    return uuid;
  },
};

export default formatUuid;
