export const ROADSHOWS_RESET = 'ROADSHOWS_RESET';
export const REQUEST_ROADSHOWS = 'REQUEST_ROADSHOWS';
export const REQUEST_ROADSHOWS_SUCCESS = 'REQUEST_ROADSHOWS_SUCCESS';
export const REQUEST_ROADSHOWS_ERROR = 'REQUEST_ROADSHOWS_ERROR';

export const ROADSHOWS_SEARCH_CHANGE = 'ROADSHOWS_SEARCH_CHANGE';
export const ROADSHOWS_SORT_CHANGE = 'ROADSHOWS_SORT_CHANGE';
export const ROADSHOWS_PAGE_CHANGE = 'ROADSHOWS_PAGE_CHANGE';
export const ROADSHOWS_ITEMS_PER_PAGE_CHANGE = 'ROADSHOWS_ITEMS_PER_PAGE_CHANGE';

export const RESET_ROADSHOW_ITEM = 'RESET_ROADSHOW_ITEM';
export const REQUEST_ROADSHOW = 'REQUEST_ROADSHOW';
export const REQUEST_ROADSHOW_SETTINGS_SUCCESS = 'REQUEST_ROADSHOW_SETTINGS_SUCCESS';
export const RESET_ROADSHOW_SETTINGS = 'RESET_ROADSHOW_SETTINGS';
export const REQUEST_ROADSHOW_SUCCESS = 'REQUEST_ROADSHOW_SUCCESS';
export const REQUEST_ROADSHOW_ERROR = 'REQUEST_ROADSHOW_ERROR';

export const TOGGLE_ROADSHOW_PUBLISHED = 'TOGGLE_ROADSHOW_PUBLISHED';

export const SUBSCRIBE_TO_ROADSHOW = 'SUBSCRIBE_TO_ROADSHOW';
export const SUBSCRIBE_TO_ROADSHOW_SUCCESS = 'SUBSCRIBE_TO_ROADSHOW_SUCCESS';
export const SUBSCRIBE_TO_ROADSHOW_ERROR = 'SUBSCRIBE_TO_ROADSHOW_ERROR';

export const UNSUBSCRIBE_FROM_ROADSHOW = 'UNSUBSCRIBE_FROM_ROADSHOW';
export const UNSUBSCRIBE_FROM_ROADSHOW_SUCCESS = 'UNSUBSCRIBE_FROM_ROADSHOW_SUCCESS';
export const UNSUBSCRIBE_FROM_ROADSHOW_ERROR = 'UNSUBSCRIBE_FROM_ROADSHOW_ERROR';

export const GET_APPLICATION_IS_BLACKLISTED = 'GET_APPLICATION_IS_BLACKLISTED';
export const GET_APPLICATION_IS_BLACKLISTED_ERROR = 'GET_APPLICATION_IS_BLACKLISTED_ERROR';

export const TOGGLE_INVESTOR_TARGETING = 'TOGGLE_INVESTOR_TARGETING';
