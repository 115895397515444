import * as actionTypes from './actionTypes';

const initialState = {
  isFetching: false,
  accountTotalCount: 0,
  collection: [],
  page: 1,
  perPage: 25,
  sortBy: 'accountTitle',
  sortOrder: 'asc',
};

export default function accountsActivity(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_ACCOUNTS_ACTIVITY:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.REQUEST_ACCOUNTS_ACTIVITY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.payload,
      };
    case actionTypes.REQUEST_ACCOUNTS_ACTIVITY_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.ACCOUNTS_ACTIVITY_SORT_CHANGE:
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    case actionTypes.ACCOUNTS_ACTIVITY_PAGE_CHANGE:
      return {
        ...state,
        page: action.payload.page,
      };
    case actionTypes.ACCOUNTS_ACTIVITY_ITEMS_PER_PAGE_CHANGE:
      return {
        ...state,
        perPage: action.payload.perPage,
        page: 1,
      };
    case actionTypes.RESET_ACCOUNTS_ACTIVITY:
      return initialState;
    default:
      return state;
  }
}
