import { IValidationError } from './interfaces/ValidationError';

export const domainRegexp = /^(?!:\/\/)([a-zA-Z0-9]+\.)?([a-zA-Z0-9][a-zA-Z0-9-]+\.){1,6}[a-zA-Z]{2,20}?$/i;

interface IParams {
  value: string,
  fieldName: string,
}

export const validateDomain = (params: IParams): IValidationError => {
  if (params.value && !domainRegexp.test(params.value)) {
    return { [params.fieldName]: 'Invalid domain entered' };
  }
  return null;
};
