import React, { createContext, useContext, useState } from 'react';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import { useDIContext } from '@/Framework/DI/DIContext';

const useDataroomTwoFactorAuthentication = () => {
  const initialTwoFactorModalState = {
    isModalOpen: false,
    entityName: '',
    onSuccessCallback: () => {},
    onCloseCallback: () => {},
  };

  const { session } = useSessionContext();
  const isSessionTwoFactorVerified = session?.twoFactor;
  const userEmail = session?.email;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [modalData, _setModalData] = useState(initialTwoFactorModalState);
  const [twoFactorAccessError, setTwoFactorAccessError] = useState(false);

  const setModalData = (data) => {
    _setModalData((state) => ({
      ...state,
      ...data,
    }));
  };

  const resetModalData = () => {
    _setModalData(initialTwoFactorModalState);
  };

  return {
    isSessionTwoFactorVerified,
    isModalOpen: modalData.isModalOpen,
    entityName: modalData.entityName,
    onSuccessCallback: modalData.onSuccessCallback,
    onCloseCallback: modalData.onCloseCallback,
    setModalData,
    resetModalData,
    twoFactorAccessError,
    setTwoFactorAccessError,
    userEmail,
  };
};

type TDataroomTwoFactorAuthenticationContext = ReturnType<typeof useDataroomTwoFactorAuthentication>;

export const DataroomTwoFactorAuthenticationContext = createContext<TDataroomTwoFactorAuthenticationContext>(null);

export const useDataroomTwoFactorAuthenticationContext = () => {
  const context = useContext(DataroomTwoFactorAuthenticationContext);
  if (!context) {
    throw new Error('useDataroomTwoFactorAuthenticationContext must be used within the DataroomTwoFactorAuthenticationContext');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const DataroomTwoFactorAuthenticationContextProvider = ({ children } : IProps) => {
  const dataroomTwoFactorAuthenticationContextValue = useDataroomTwoFactorAuthentication();
  const { container } = useDIContext();
  container.bindContextValue(DataroomTwoFactorAuthenticationContext, dataroomTwoFactorAuthenticationContextValue);

  return (
    <DataroomTwoFactorAuthenticationContext.Provider value={ dataroomTwoFactorAuthenticationContextValue }>
      { children }
    </DataroomTwoFactorAuthenticationContext.Provider>
  );
};

export default DataroomTwoFactorAuthenticationContextProvider;
