import isServer from '@/Framework/Router/Next/isServer';

/* eslint-disable global-require */
require('reflect-metadata');

if (!isServer()) {
  require('ts-replace-all'); // Chrome < 85, Firefox < 77

  require('@kuzorov/smoothscroll-polyfill'); // IE 11; Safari
  require('@kuzorov/webstorage-fallback'); // Old Safari; Chrome (incognito)
}

// Add location-change event for history (used for Google Analytics tracking)
if (!isServer()) {
  let { pushState } = window.history;
  let { replaceState } = window.history;

  // eslint-disable-next-line func-names
  window.history.pushState = function() {
    // eslint-disable-next-line prefer-rest-params
    pushState.apply(window.history, arguments);
    window.dispatchEvent(new Event('pushstate'));
    window.dispatchEvent(new Event('locationchange'));
  };

  // eslint-disable-next-line func-names
  window.history.replaceState = function() {
    // eslint-disable-next-line prefer-rest-params
    replaceState.apply(window.history, arguments);
    window.dispatchEvent(new Event('replacestate'));
    window.dispatchEvent(new Event('locationchange'));
  };

  window.addEventListener('popstate', () => {
    window.dispatchEvent(new Event('locationchange'));
  });
}
