class ClientCookie {
  constructor(private _cookie) {}

  public static fromRequest(request): ClientCookie {
    return new ClientCookie(request.cookies);
  }

  get cookie() {
    return this._cookie;
  }
}

export default ClientCookie;
