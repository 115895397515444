import flatten from 'lodash/flatten';
import urlJoin from 'url-join';
import config from '@/Framework/config';
import base from '@/Framework/url/baseUrl';
import shouldUseProxy from '@/Framework/url/helpers/shouldUseProxy';
import isServer from '@/Framework/Router/Next/isServer';
import getUnixTimestamp from '@/Framework/DateTime/getUnixTimestamp';
import shouldUseInternalNetwork from '@/Framework/Router/Next/shouldUseInternalNetwork';
import { base64Encode } from '@/Framework/dataHelpers/base64/base64Encode';
import { jsonStringify } from '@/Framework/dataHelpers/json/jsonStringify';

export default {
  /**
   * Get base users url
   */
  getUrl(uris: string | string[] = []) {
    if (!Array.isArray(uris)) {
      uris = [uris];
    }
    uris = flatten(uris).filter((uri) => !!uri);
    return urlJoin(`${ config.protocol }//${ config.usersSubdomain }.${ config.globalDomain }`, ...uris);
  },

  /**
   * Get base helpers url
   *
   * @param {Array|String} uris
   *
   * @return {String}
   */
  getApiUrl(uris: string | string[] = []): string {
    return base.getApiUrl('users/api', uris);
  },

  /**
   * Get base rpc helpers url
   *
   * @return {String}
   */
  getRpcUrl(): string {
    if (shouldUseInternalNetwork()) {
      return this.getInternalRpcUrl();
    }

    if (shouldUseProxy()) {
      return this.getProxyApiRpcPath();
    }

    return base.getApiUrl('/users/rpc');
  },

  getInternalRpcUrl(): string {
    return `${ config.tenant.users.api.internal }/rpc`;
  },
  /**
   * Gets proxy path.
   *
   * @return {String}
   */
  getProxyPath() {
    return `${ window.location.protocol }//${ window.location.hostname }/proxy/api/users`;
  },

  /**
   * Gets proxy api path.
   *
   * @return {String}
   */
  getProxyApiPath() {
    return `${ this.getProxyPath() }/api`;
  },

  /**
   * Gets proxy api rpc path.
   *
   * @return {String}
   */
  getProxyApiRpcPath() {
    return `${ this.getProxyPath() }/rpc`;
  },

  /**
   * Gets proxy api logout path.
   *
   * @return {String}
   */
  getProxyApiLogoutPath() {
    return `${ this.getProxyApiPath() }/session/logout`;
  },

  /**
   * Get user session propagation url
   *
   * @param {String} callbackUrl
   * @return {String}
   */
  getSessionPropagateUrl(callbackUrl) {
    // @ts-ignore
    const query = new URLSearchParams({ callbackUrl, t: getUnixTimestamp() });
    const newUrl = new URL(`?${ query.toString() }`, this.getApiUrl('session/propagate/'));
    return newUrl.href;
  },

  /**
   * Get logout url
   *
   * @param {String} callbackUrl
   *
   * @return {String}
   */
  getLogoutUrl(callbackUrl) {
    // @ts-ignore
    const query = new URLSearchParams({ callbackUrl, t: getUnixTimestamp() });
    const newUrl = new URL(`?${ query.toString() }`, this.getApiUrl('/session/logout/'));
    return newUrl.href;
  },

  /**
   * Get user profile link with correct callbackUrl and tenant
   *
   * @param {String} tenant
   *
   * @return {String}
   */
  getProfileUrl(tenant) {
    const query = new URLSearchParams({ tenant, callbackUrl: !isServer() && window.location.href });
    const newUrl = new URL(`?${ query.toString() }`, this.getUrl('/profile/'));
    return newUrl.href;
  },

  /**
   * Get check password link url for two factor auth with correct callbackUrl and tenant
   *
   * @param {String} tenant
   *
   * @return {String}
   */
  getCheckPasswordUrl(tenant) {
    const query = new URLSearchParams({ tenant, callbackUrl: !isServer() && window.location.href });
    const newUrl = new URL(`?${ query.toString() }`, this.getUrl('/two_factor_authentication/check_password/'));
    return newUrl.href;
  },

  /**
   * Get login url
   *
   * @param {String} callbackUrl
   * @param {String} tenant
   * @param {Object} [customParams]
   *
   * @return {String}
   */
  getLoginUrl(callbackUrl, tenant, customParams?: { [key: string]: any }) {
    return this.getUrl(`/login?callbackUrl=${ callbackUrl }&tenant=${ tenant }${ customParams ? `&customParams=${ base64Encode(jsonStringify(customParams)) }` : '' }`);
  },
  /**
   * Get register url
   *
   * @param {String} callbackUrl
   * @param {String} tenant
   *
   * @return {String}
   */
  getRegisterUrl(callbackUrl, tenant) {
    return this.getUrl(`/register?callbackUrl=${ callbackUrl }&tenant=${ tenant }`);
  },

  /**
   * Get callback url based on current host
   *
   * @param {String} callbackUrl
   * @returns {String}
   */
  getCallbackUrl(callbackUrl = this.getBaseUrl()) {
    return encodeURIComponent(callbackUrl);
  },

  /**
   * @param {String} email
   * @param {String|null} callbackUrl
   * @returns {String}
   */
  getSsoLoginUrl(email, callbackUrl) {
    const loginPath = '/sso/login/';
    const query = new URLSearchParams({ ...(callbackUrl && { callbackUrl }) });
    const newUrl = new URL(`?${ query.toString() }&email=${ encodeURIComponent(email) }`, this.getApiUrl(loginPath));
    return newUrl.href;
  },

  /**
   * @param {String} ssoProvider
   * @param {String|null} callbackUrl
   * @returns {String}
   */
  getSsoVerifyLoginUrl(ssoProvider, callbackUrl) {
    const loginPath = `/sso/${ ssoProvider }/login/verify/`;
    const query = new URLSearchParams({ ...(callbackUrl && { callbackUrl }) });
    const newUrl = new URL(`?${ query.toString() }`, this.getApiUrl(loginPath));
    return newUrl.href;
  },

  /**
   * @param {Array||String} uris
   * @return {String}
   */
  getBaseUrl(...uris) {
    return this.buildUrl(config.protocol, config.host, ...uris);
  },
};
