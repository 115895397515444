import debounce from 'lodash/debounce';
import SyntheticEventEmitter from '@/Framework/dataHelpers/event/SyntheticEventEmitter';

export const SYNTHETIC_ACTIVITY_EVENT = 'syntheticActivity';

/**
 * Emits synthetic user activity to make it not idle
 */
export default debounce(() => {
  SyntheticEventEmitter.emit(SYNTHETIC_ACTIVITY_EVENT, { bubbles: true });
}, 5000, { maxWait: 10000 });
