import flatten from 'lodash/flatten';
import urlJoin from 'url-join';
import config from '@/Framework/config';
import baseUrl from '@/Framework/url/baseUrl';

export default {
  getUrl(uris: Array<string> | string = []) {
    if (!Array.isArray(uris)) {
      uris = [uris];
    }
    uris = flatten(uris).filter((uri) => !!uri);
    return urlJoin(`${ config.protocol }//${ config.tenant.tenantEvercall.hostname }`, ...uris);
  },

  getRpcUrl({ disableProxy = false }: { disableProxy?: boolean } = {}): string {
    return baseUrl.getRpcUrl({ disableProxy });
  },
};
