import * as Sentry from '@sentry/nextjs';

const sentryLogLevels = {
  warn: 'warning',
  error: 'error',
};

/**
 * @private
 */
function captureLog({ severity, message, context }) {
  if (context) {
    // eslint-disable-next-line no-console
    console[severity](message, context);
  } else {
    // eslint-disable-next-line no-console
    console[severity](message);
  }

  if (sentryLogLevels[severity]) {
    captureExternalLog({ error: message, context, severity: sentryLogLevels[severity] });
  }
}

function captureExternalLog({ error, context, severity }) {
  Sentry.withScope((scope) => {
    scope.setLevel(severity);
    if (context) {
      scope.setExtra('Error context', context);
    }

    return Sentry.captureException(error);
  });
}

class Logger {
  static log(message, context = undefined) {
    captureLog({ message, context, severity: 'info' });
  }

  static debug(message, context = undefined) {
    captureLog({ message, context, severity: 'debug' });
  }

  static info(message, context = undefined) {
    captureLog({ message, context, severity: 'info' });
  }

  static warn(message, context = undefined) {
    captureLog({ message, context, severity: 'warn' });
  }

  static error(message, context = undefined) {
    captureLog({ message, context, severity: 'error' });
  }
}

export default Logger;
