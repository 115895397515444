import flatten from 'lodash/flatten';
import * as baseUrlJoin from 'url-join';

/**
 * @param {Array||String} uris
 * @return {String}
 */
export const urlJoin = (...uris) => baseUrlJoin(...flatten(uris).filter((uri) => !!uri));

export default urlJoin;
