export const jsonStringify = (
  value: any,
  replacer?: (this: any, key: string, value: any) => any,
  space?: string | number,
): string => {
  try {
    return JSON.stringify(value, replacer, space);
  } catch (e) {
    return '';
  }
};
