type Key = string;
type Value = object | string | boolean;

class LocalStorageRepository {
  setItem(key: string, value: object): {
    key: Key,
    value: Value,
  } | boolean {
    if (key) {
      localStorage.setItem(key, JSON.stringify(value));

      return {
        key,
        value: this.getItem(key),
      };
    }
    return false;
  }

  getItem<T extends Value>(key: Key): Value | T {
    if (key) {
      try {
        return JSON.parse(localStorage.getItem(key));
      } catch (e) {
        if (localStorage[key]) {
          return localStorage.getItem(key);
        }

        return null;
      }
    }
    return false;
  }

  removeItem(key: Key): boolean {
    if (key) {
      localStorage.removeItem(key);
      return !this.getItem(key);
    }
    return false;
  }

  clear() {
    localStorage.clear();
  }
}

export default LocalStorageRepository;
