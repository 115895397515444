import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import NotificationContainer from './NotificationContainer';

const InitNotifications = () => {
  useEffect(() => {
    createRoot(document.getElementById('notification')).render(
      <NotificationContainer />,
    );
  }, []);

  return null;
};

export default InitNotifications;
