import downloadFile from '@/Framework/api/downloadFile';
import isServer from '@/Framework/Router/Next/isServer';

export function initAutoDownload(history) {
  if (isServer()) {
    return;
  }

  const searchParams = new URLSearchParams(window.location.search);
  const autoDownload = searchParams.get('auto_download');
  if (autoDownload) {
    downloadFile(autoDownload);
    searchParams.delete('auto_download');
    history.replace({
      pathname: window.location.pathname,
      search: searchParams.toString(),
    });
  }
}
