import { RpcError } from '@dealroadshow/json-rpc-dispatcher';
import { checkSessionWithZeroTolerance } from '@/users/application/Session/interceptors/helpers';

// We dont want to check session here, because this methods are used to retrieve session data, and there is
// High possibility of issues with concurrency that are hard to debug.
const methodWhitelist = [
  'session.get_current_session',
  'investor.auth',
  'auth',
  'session.login',
  'user.authenticate',
  'session.get_current_user',
  'session.invalidate',
  'session.prolong',
];

const checkSession = (container) => async (response, request) => {
  if (!(response instanceof RpcError)) {
    return response;
  }

  if (
    (
      // This is old agreement, that we want to get rid of, but for now
      // All errors with code > 1000 || < 2000 || === 401 should trigger user log-out
      ((response.getCode() > 1000 && response.getCode() < 2000) || response.getCode() === 401) &&
      !methodWhitelist.includes(request.method)
    )
  ) {
    await checkSessionWithZeroTolerance(container);
  }

  return response;
};

export default checkSession;
