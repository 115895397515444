import React from 'react';
import * as Sentry from '@sentry/nextjs';
import Fallback from './Fallback';

interface IProps {
  children: React.ReactNode,
  fallback?: React.ReactElement | typeof Fallback,
}

function ErrorBoundary({ fallback = Fallback, children }: IProps) {
  return (
    // @ts-ignore
    <Sentry.ErrorBoundary fallback={ fallback }>
      { children }
    </Sentry.ErrorBoundary>
  );
}

/**
 * HoC for wrapping components
 * @param {Node|Function} Component
 * @param {Node} fallback
 */
export function withErrorBoundary(Component, fallback = Fallback) {
  // @ts-ignore
  return Sentry.withErrorBoundary(Component, { fallback });
}

export default ErrorBoundary;
