/**
 * This filter is created in order to fix Non-Error promise rejection captured with value: Object Not Found Matching
 * Id:2 issue:
 * - https://finsight.myjetbrains.com/youtrack/issue/FIN-11808
 * - https://sentry.io/organizations/finsight-group-inc/issues/2460467724/?project=5192325&query=is%3Aunresolved
 *
 * More info regarding this issue:
 * https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
 *
 * @return {boolean}
 */
function hasObjectNotFoundMatchingIdError(event) {
  return typeof event?.extra?.__serialized__ === 'string' && event?.extra?.__serialized__.startsWith('Object Not Found Matching Id:');
}

export default hasObjectNotFoundMatchingIdError;
