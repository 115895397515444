export const PREMIUMIZATION = 'premiumization';
export const RESEARCHROOM_FEATURE = 'researchroomEnabled';

export const featureToggle = {
  [PREMIUMIZATION]: {
    cookieName: PREMIUMIZATION,
  },
  [RESEARCHROOM_FEATURE]: {
    cookieName: RESEARCHROOM_FEATURE,
  },
};
