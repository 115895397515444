export default {
  BASE: '/',
  BOOK_DEMO: '/book-demo',
  DASHBOARD: '/dash',
  SCHEDULE: '/schedule',
  FEATURES: '/features',
  PROCESS: '/process',
  PRICING: '/pricing',
  OACC_REGISTRATION: '/oacc',
};
