import { Component } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import noop from 'lodash/noop';

interface IProps {
  to?: string | { [key: string]: any } | ((location: string) => string | { [key: string]: any }),
  location?: { [key: string]: any },
  children?: React.ReactNode,
  onClick?: (event: React.MouseEvent) => void,
  disabled?: boolean,
  className?: string,
  name?: string,
}

const defaultProps: Partial<IProps> = {
  onClick: noop,
  className: '',
  to: '#',
};

@withRouter
class Link extends Component<IProps> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps: Partial<IProps>;

  handleClick = (event: React.MouseEvent) => {
    if (this.props.disabled) {
      event.preventDefault();
    }

    if (typeof this.props.to === 'object') {
      let {
        pathname,
        search = '',
        hash = '',
      } = this.props.to;
      const { location } = this.props;

      // Prepend with ? to match props.location.search
      if (search[0] !== '?') {
        search = `?${ search }`;
      }

      if (
        pathname === location.pathname
        && search === location.search
        && hash === location.hash
      ) {
        event.preventDefault();
      }
    } else {
      const { to, location } = this.props;

      if (to === location.pathname + location.search + location.hash) {
        event.preventDefault();
      }
    }

    this.props.onClick(event);
  };

  render() {
    const { children, to, disabled, name } = this.props;
    return (
      <ReactLink
        { ...(name && { name }) }
        data-test={ name }
        className={ this.props.className }
        to={ to }
        onClick={ this.handleClick }
        disabled={ disabled }
      >
        { children }
      </ReactLink>
    );
  }
}

Link.defaultProps = defaultProps;

export default Link;
